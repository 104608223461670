import {
  type FeedTypeNodeResult,
  type FeedWithAugTypes,
  ReadershipConfigurationQuery,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import type { SortOptions } from "@/types/shared";
import { format } from "date-fns";
import useFeedStore from "../useFeedStore";

// Common utilities shared between feed and search actions
export const getFormattedDates = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  filterDays: number,
) => {
  const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

  const startDateFormatted = startDate
    ? format(startDate, "yyyy-MM-dd")
    : format(
        new Date(Date.now() - filterDays * MILLISECONDS_IN_A_DAY),
        "yyyy-MM-dd",
      );

  const endDateFormatted = endDate
    ? format(endDate, "yyyy-MM-dd")
    : format(new Date(), "yyyy-MM-dd");

  return {
    startDateFormatted,
    endDateFormatted,
  };
};

export const handleFeedItems = (
  feedItems: Partial<FeedWithAugTypes>[],
  feedId: number,
  pageInfo?: {
    startCursor: string | null;
    endCursor: string | null;
    hasNextPage: boolean;
  } | null,
  append = false,
) => {
  useFeedStore.setState(
    {
      refreshingScore: false,
      feedItems: append
        ? [...useFeedStore.getState().feedItems, ...feedItems]
        : feedItems,
      ...(pageInfo && {
        cursor: pageInfo.endCursor,
        hasNextPage: pageInfo.hasNextPage ?? false,
      }),
      fetchingFeed: false,
    },
    false,
    `data loaded for feed ${feedId}`,
  );
};

type SortDirection = "DESC_NULLS_LAST" | "ASC_NULLS_LAST" | null;

interface SortParams {
  scoreSort: SortDirection;
  dateSort: SortDirection;
}

export const getSortParams = (sortOption: SortOptions): SortParams => {
  switch (sortOption) {
    case "SCORE_DESC":
      return {
        scoreSort: "DESC_NULLS_LAST",
        dateSort: null,
      };
    case "SCORE_ASC":
      return {
        scoreSort: "ASC_NULLS_LAST",
        dateSort: null,
      };
    case "DATE_DESC":
      return {
        scoreSort: null,
        dateSort: "DESC_NULLS_LAST",
      };
    case "DATE_ASC":
      return {
        scoreSort: null,
        dateSort: "ASC_NULLS_LAST",
      };
  }
};

export const mapNodeToFeedItem = (
  node: FeedTypeNodeResult,
): Partial<FeedWithAugTypes> => ({
  id: Number.parseInt(node.id as string, 10),
  articleId: Number.parseInt(node.article.id, 10),
  tierLevel: `${node.tier}`,
  headline: node.article.headline ?? null,
  overriddenHeadline: node.overrides?.headline ?? null,
  imageUrl: node.article.mainImage?.image?.fullSize ?? null,
  maxScore: node.score?.customScore ?? null,
  articlePublisher: node.article.publisher
    ? {
        id: Number.parseInt(node.article.publisher.id, 10) ?? null,
        logoUrl: node.article.publisher.logoUrl ?? null,
        name: node.article.publisher.name,
        url: node.article.publisher.url ?? null,
      }
    : null,
  overriddenArticlePublisher: node.overrides?.publisher
    ? {
        id: +node.overrides.publisher.id,
        logoUrl: node.overrides.publisher.logoUrl,
        name: node.overrides.publisher.name,
        url: node.overrides.publisher.url,
      }
    : null,
  articleAuthors:
    node.article.authors?.map((author) => ({
      id: author.id ?? null,
      name: author.name,
    })) ?? [],
  overriddenArticleAuthors:
    (node.overrides?.authors?.length ?? 0) > 0
      ? // biome-ignore lint/style/noNonNullAssertion: <condititional above lands it>
        node.overrides!.authors!.map((author) => ({
          id: author.id,
          name: author.name,
        }))
      : null,
  overriddenDatePublished: null,
  articleNewsCategory: node.article.category ?? null,
  overriddenArticleNewsCategory: node.overrides?.category ?? null,
  articleReadership: node.article.publisher.monthlyVisitors,
  articleSentiment: node.sentiment
    ? {
        polarity: node.sentiment.polarity,
        rationale: node.sentiment.rationale,
      }
    : null,
  overriddenArticleSentiment: node.overrides?.sentiment
    ? {
        polarity: node.overrides.sentiment.polarity,
        rationale: node.overrides.sentiment.rationale,
        intensity: 0,
      }
    : null,
  firstArticleMention:
    (node.firstMention as {
      term: string;
      snippet: string;
      location: string;
    }) ?? null,
  articleUrl: node.article.url,
  datePublished:
    node.overrides?.datePublished ??
    node.article.datePublished ??
    node.article.createdAt,
  articleLastUpdateDate:
    node.overrides?.datePublished ??
    node.article.datePublished ??
    node.article.createdAt,
  knownTags: null,
  maxDomainAuthority: node.article.publisher.domainAuthorityScore,
  prominence: node.overrides?.prominence ?? node.prominence,
  maxSocial: node.article.social?.totalSocial ?? 0,
  sortDate: node.article.datePublished ?? node.article.createdAt,
  summary: `${node.article.genericSummaries[0]?.text ?? ""}\n${
    node.feedSummary?.text ?? ""
  }`,
  overriddenSummary: node.overrides?.summary ?? null,
  topics: null,
});

export const fetchTierConfiguration = () => {
  return client.query(ReadershipConfigurationQuery, {}).toPromise();
};
