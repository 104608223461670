import { isDevelopment } from "@/config";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { createWithEqualityFn as create } from "zustand/traditional";
import type { FilterComponentStateSlice } from "./news/filterComponentState.slice";
import { createFilterComponentStateSlice } from "./news/filterComponentState.slice";

const useFilterComponentStore = create<FilterComponentStateSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createFilterComponentStateSlice(...a),
      }),
      {
        name: "filterComponentStore",
        storage: createJSONStorage(() => localStorage),
        version: 1,
        migrate: (persistedState) => {
          const emptyState: FilterComponentStateSlice = {
            filterComponentStates: {
              IMPACT_SCORE: true,
            },
            setFilterComponentState: () => {},
            toggleFilterComponentState: () => {},
          };

          if (!persistedState) return emptyState;

          return {
            ...emptyState,
            filterComponentStates: {
              ...emptyState.filterComponentStates,
              ...((persistedState as FilterComponentStateSlice)
                ?.filterComponentStates || {}),
            },
          };
        },
      },
    ),
    {
      name: "filterComponentStore",
      enabled: isDevelopment,
      store: "filterComponents",
    },
  ),
);

export default useFilterComponentStore;
