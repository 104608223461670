import { isTokenExpired } from "@/lib/utils";
import useAuth from "@/store/auth.slice";
import { apiEndpointBaseUrl } from "./constants";

let refreshPromise: Promise<string | null> | null = null;

const refreshToken = async (refreshToken: string): Promise<string | null> => {
  if (isTokenExpired(refreshToken)) {
    return null;
  }

  try {
    const response = await fetch(`${apiEndpointBaseUrl}/auth/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
      throw new Error("Token refresh failed");
    }

    const body = await response.json();
    useAuth.setState({
      accessToken: body.access,
      refreshToken: body.refresh,
    });
    return body.access;
  } catch (error) {
    console.error("Error refreshing token:", error);
  }
  return null;
};

export const getAuthToken = async (
  token = useAuth.getState().accessToken || "",
): Promise<string | null> => {
  // If token is valid, return it immediately
  if (token && !isTokenExpired(token)) return token;

  const refresh = useAuth.getState().refreshToken || "";
  if (refresh && !isTokenExpired(refresh)) {
    // If there's already a refresh in progress, return its promise
    if (refreshPromise) {
      return refreshPromise;
    }

    // Create new refresh promise
    refreshPromise = refreshToken(refresh);

    try {
      // Wait for the refresh to complete
      const newToken = await refreshPromise;
      return newToken;
    } finally {
      // Clear the refresh promise so future calls can refresh if needed
      refreshPromise = null;
    }
  }
  return null;
};
