import toast from "@/components/ui/toast";
import {
  type ArticleCreationDataType,
  type ArticleCreationStatusType,
  type ArticleNewsCategoryType,
  type ArticlePublisherType,
  type FeedWithAugTypes,
  UpdateArticleMutation,
  createArticleSubscription,
  deleteFeedArticleMutation,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { toSnakeCase } from "@/lib/utils/prettyName";
import type { ArticleEditFn } from "@/types/article";
import type { SortOptions } from "@/types/shared";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import useArticleDeletionStore from "./articleDelete.slice";
import { updatePagedFeed } from "./feed.actions";
import { FilterCategory } from "./feed.types";

export const changeSort = (sortOrder: SortOptions) => {
  useFeedParamsStore.setState(
    { sortOrder },
    false,
    "[User] Sort: Updated order",
  );
  updatePagedFeed();
};

export const addArticle = async (articleUrl: string) => {
  const feedId = useFeedStore.getState().feedId;

  if (!feedId) {
    console.error("Could not find the feed ID in the store");
    return Promise.reject(new Error("Feed ID is missing"));
  }

  return new Promise<ArticleCreationDataType>((resolve, reject) => {
    client
      .subscription(createArticleSubscription, {
        articleUrl,
        feedId: `${feedId}`,
      })
      .subscribe(({ data, error }) => {
        if (error) {
          console.error(error);
          reject(error);
          return;
        }

        const validStatuses: ArticleCreationStatusType[] = [
          "COMPLETE",
          "PARTIAL_COMPLETE",
          "ERROR",
        ];

        if (
          data?.articleCreation &&
          validStatuses.includes(data.articleCreation.status)
        ) {
          resolve(data.articleCreation);
          return;
        }
      });
  });
};

const updateArticleInFilterSets = (
  articleId: number,
  updates: Parameters<ArticleEditFn>[0],
) => {
  const { categoryAppliedFilters } = useFeedStore.getState();

  // Check each possible override field
  for (const [category, filter] of Object.entries(categoryAppliedFilters)) {
    if (!filter.itemCounts) continue;

    // Remove from all sets in this category first
    for (const set of Object.values(filter.itemCounts)) {
      set.delete(articleId);
    }

    // Add to new set(s) based on update type
    switch (category) {
      case FilterCategory.CATEGORY:
        if (updates.category) {
          const normalizedCategory = updates.category.toLowerCase().trim();
          const matchingCategory = Object.keys(filter.itemCounts).find(
            (key) => key.toLowerCase().trim() === normalizedCategory,
          );
          if (matchingCategory) {
            filter.itemCounts[matchingCategory]?.add(articleId);
          }

          // Check if "unknown" category is now empty
          const unknownKey = Object.keys(filter.itemCounts).find(
            (key) => key.toLowerCase().trim() === "unknown",
          );
          if (unknownKey && filter.itemCounts[unknownKey]?.size === 0) {
            delete filter.itemCounts[unknownKey];
          }
        }
        break;
      case FilterCategory.PROMINENCE:
        if (updates.prominence) {
          for (const p of updates.prominence) {
            const normalizedProminence = p.toLowerCase().trim();
            const matchingProminence = Object.keys(filter.itemCounts).find(
              (key) => key.toLowerCase().trim() === normalizedProminence,
            );
            if (matchingProminence) {
              filter.itemCounts[matchingProminence]?.add(articleId);
            }
          }
        }
        break;
      case FilterCategory.SENTIMENT:
        if (updates.sentimentPolarity) {
          const normalizedSentiment = updates.sentimentPolarity
            .toLowerCase()
            .trim();
          const matchingSentiment = Object.keys(filter.itemCounts).find(
            (key) => key.toLowerCase().trim() === normalizedSentiment,
          );
          if (matchingSentiment) {
            filter.itemCounts[matchingSentiment]?.add(articleId);
          }
        }
        break;
      case FilterCategory.PUBLISHER:
        if (updates.publisher?.id) {
          // For publisher we use the ID as the key
          if (!filter.itemCounts[updates.publisher.id]) {
            filter.itemCounts[updates.publisher.id] = new Set<number>();
          }
          filter.itemCounts[updates.publisher.id]?.add(articleId);
        }
        break;
      case FilterCategory.AUTHOR:
        if (updates.author) {
          const normalizedAuthor = updates.author.toLowerCase().trim();
          let matchingAuthor = Object.keys(filter.itemCounts).find(
            (key) => key.toLowerCase().trim() === normalizedAuthor,
          );
          if (!matchingAuthor) {
            // If no matching author found, add a new one
            matchingAuthor = updates.author;
            filter.itemCounts[matchingAuthor] = new Set<number>();
          }
          filter.itemCounts[matchingAuthor]?.add(articleId);
        }
        break;
    }
  }

  useFeedStore.setState({ categoryAppliedFilters });
};

export const updateArticle: ArticleEditFn = async (changes) => {
  const { articleId, ...overrides } = changes;
  const {
    headline,
    summary,
    author,
    publisher,
    sentimentPolarity,
    sentimentRationale,
    category,
    isLede,
    prominence,
  } = overrides;

  const { feedItems, feedId } = useFeedStore.getState();

  const updateItemFn = <T extends Partial<FeedWithAugTypes>>(item: T): T => {
    if (item?.articleId !== +articleId) return item;
    if (headline) item.headline = headline;
    if (summary) item.summary = summary;
    if (author && item.articleAuthors?.[0]) {
      const [mainAuthor, ...rest] = item.articleAuthors;
      item.articleAuthors = [{ ...mainAuthor, name: author }, ...rest];
    }
    if (sentimentPolarity && sentimentRationale) {
      item.articleSentiment = {
        polarity: sentimentPolarity,
        rationale: sentimentRationale,
      };
    }
    if (category)
      item.articleNewsCategory = toSnakeCase(
        category,
      ) as ArticleNewsCategoryType;
    if (publisher) {
      item.articlePublisher = {
        ...item.articlePublisher,
        name: publisher.name,
        url: publisher.url,
        id: +publisher.id,
        logoUrl: publisher.logoUrl,
      } as ArticlePublisherType;
    }
    if (typeof isLede === "boolean") {
      item.isLede = isLede;
    }
    if (prominence) {
      item.prominence = Array.isArray(prominence) ? prominence : [prominence];
    }
    return item;
  };

  if (!feedId) return false;

  const result = client
    .mutation(UpdateArticleMutation, {
      feedId,
      feedArticleId: +articleId,
      overrides,
    })
    .toPromise();

  const { data } = await result;

  const overrideFeedData = data?.overrideFeedArticle;

  if (!overrideFeedData) return false;

  if (overrideFeedData.__typename === "OperationInfo") {
    for (const message of overrideFeedData.messages) {
      toast.error(message.message);
    }

    return false;
  }

  if (
    overrideFeedData.__typename === "FeedArticleType" &&
    overrideFeedData.id
  ) {
    const updatedFeedItems = feedItems.map(updateItemFn);

    useFeedStore.setState(
      {
        feedItems: updatedFeedItems,
      },
      false,
      "[User] Article: Updated details",
    );

    // Update filter sets on success
    updateArticleInFilterSets(+articleId, changes);

    return true;
  }
  return false;
};

export const removeArticles = async (articleIds: number[], reason: string) => {
  const { feedId } = useFeedStore.getState();

  if (!feedId) {
    console.error("Could not find the feed ID in the store");
    return Promise.reject(new Error("Feed ID is missing"));
  }

  const result = await client
    .mutation(deleteFeedArticleMutation, {
      input: {
        feedId: feedId.toString(),
        feedArticleId: articleIds.map((id) => id.toString()),
        reason: reason,
      },
    })
    .toPromise();

  if (result.data?.deleteFeedArticle.__typename === "OperationInfo") {
    const messages = result.data.deleteFeedArticle.messages;
    for (const message of messages) {
      toast.error(message.message);
    }
    return false;
  }

  const { feedItems, allFeedIds, totalCount, categoryAppliedFilters } =
    useFeedStore.getState();

  // Create a set of IDs to delete
  const deleteIdsSet = new Set(articleIds);
  const deleteIdsStringSet = new Set(articleIds.map(String));

  // Create a new copy of categoryAppliedFilters with deleted IDs removed
  const updatedCategoryFilters = { ...categoryAppliedFilters };
  for (const filter of Object.values(updatedCategoryFilters)) {
    if (!filter.itemCounts) continue;

    for (const countSet of Object.values(filter.itemCounts)) {
      for (const id of deleteIdsSet) {
        countSet.delete(id);
      }
    }
  }

  // Update store state immediately after successful deletion
  useFeedStore.setState(
    {
      // Remove from feedItems
      feedItems: feedItems.filter(
        (item) => !deleteIdsSet.has(Number(item.articleId)),
      ),
      // Only update allFeedIds, keep filteredFeedIds for paging
      allFeedIds: allFeedIds.difference(deleteIdsStringSet),
      // Update filter counts
      categoryAppliedFilters: updatedCategoryFilters,
      // Update total count
      totalCount: Math.max(0, (totalCount ?? 0) - articleIds.length),
    },
    false,
    "[User] Articles: Deleted from feed",
  );

  // Set deleted article IDs to trigger animation
  useArticleDeletionStore.getState().setDeletedArticleIds(articleIds);

  return true;
};
