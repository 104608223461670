import { persist } from "zustand/middleware";
import { createWithEqualityFn as create } from "zustand/traditional";

interface SessionState {
  isExpired: boolean;
  showExpirationWarning: boolean;
  setExpired: (expired: boolean) => void;
  setShowWarning: (show: boolean) => void;
  handleUserInteraction: () => void;
}

const useSession = create<SessionState>()(
  persist(
    (set) => ({
      isExpired: false,
      showExpirationWarning: false,
      setExpired: (expired) =>
        set({
          isExpired: expired,
          showExpirationWarning: expired,
        }),
      setShowWarning: (show) => set({ showExpirationWarning: show }),
      handleUserInteraction: () => {
        set({ showExpirationWarning: false });
      },
    }),
    {
      name: "session-storage",
    },
  ),
);

export default useSession;
