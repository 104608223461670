import { toast } from "@/components/ui/toast";
import useAuth from "@/store/auth.slice";
import useSession from "@/store/session.slice";

export const handleSessionExpired = () => {
  // Reset auth state (which also clears storage)
  useAuth.getState().resetState();

  // Set expired state
  const sessionStore = useSession.getState();
  sessionStore.setExpired(true);

  // Notify the user
  toast.error("Your session has expired. Please log in again.");

  // Use replace to clear navigation history and prevent back navigation
  window.location.replace("/auth/login");
};
