import HeaderLayout from "@/components/layouts/HeaderLayout";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/data-display/badge";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import { useFeature } from "@/lib/feature-management";
import useFeedPanelStore from "@/store/news/feedPanel.slice";
import { useFeedMetadata, useFilterCounts } from "@/store/news/news.selectors";
import useFeedStore from "@/store/useFeedStore";
import { Plus } from "@phosphor-icons/react";
import { useShallow } from "zustand/shallow";
import FeedAddDropdown from "./FeedAddDropdown";

const FeedHeader = () => {
  const { openAddPanel } = useFeedPanelStore(
    useShallow((state) => ({
      openAddPanel: state.openAddPanel,
    })),
  );
  const feedId = useFeedStore((state) => state.feedId);
  const fetchingFeed = useFeedStore((state) => state.fetchingFeed);
  const fetchingSearch = useFeedStore((state) => state.fetchingSearch);
  const fetchingFilters = useFeedStore((state) => state.fetchingFilters);

  const loading = fetchingFeed || fetchingSearch || fetchingFilters;

  // feature flags
  const canAddArticle = useFeature("add-article", false);
  const showStoryArticleCounts = useFeature("show-story-article-counts", false);
  const { name: feedName } = useFeedMetadata();
  const { selection: articleCount } = useFilterCounts();

  if (!feedId) {
    return (
      <header className="sticky top-0 bg-white">
        <HeaderLayout>
          <div className="flex items-center">
            <h1 className="text-2xl font-serif text-text-primary mr-3">
              All Newsfeeds
            </h1>
          </div>
          <Button onPress={openAddPanel}>
            <Plus className="mr-2 size-4" weight="bold" /> New Newsfeed
          </Button>
        </HeaderLayout>
      </header>
    );
  }

  return (
    <header className="sticky top-0 bg-white">
      <HeaderLayout>
        <div className="flex flex-row flex-grow">
          <h1 className="text-2xl font-serif text-text-primary mr-4 max-w-[60%] overflow-hidden text-ellipsis line-clamp-1">
            {feedName ? feedName : "News"}
          </h1>
          {showStoryArticleCounts && (
            <div className="flex items-center gap-2">
              {!loading ? (
                <Badge status={false}>
                  {articleCount}
                  {articleCount !== 1 ? " Articles" : " Article"}
                </Badge>
              ) : (
                <Skeleton className="h-6 w-20" />
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row-reverse items-center gap-2">
          {canAddArticle && <FeedAddDropdown />}
        </div>
      </HeaderLayout>
    </header>
  );
};

export default FeedHeader;
