import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/toast";
import type { FeedDataTermsType } from "@/data-access/news";
import useFeedPanelStore from "@/store/news/feedPanel.slice";
import { useFeedMetadata } from "@/store/news/news.selectors";
import useFeedStore from "@/store/useFeedStore";
import { CircleNotch, Plus } from "@phosphor-icons/react";
import { type ChangeEvent, useEffect, useState } from "react";
import { SheetContent, SheetTitle } from "../ui/controls/sheet";
import { Label } from "../ui/field";
import { Input } from "../ui/textfield";
import KeyTerm from "./feed-settings/KeyTerm";

const NewsFeedSettingPanel = () => {
  const panelMode = useFeedPanelStore((state) => state.mode);
  const isLoading = useFeedStore((state) => state.creatingOrEditingFeed);

  const [editFeed, createFeed] = useFeedStore((state) => [
    state.editFeed,
    state.createFeed,
  ]);

  const metadata = useFeedMetadata();
  const [feedName, setFeedName] = useState<string>(metadata.name);
  const [keyTerms, setKeyTerms] = useState<FeedDataTermsType[]>(
    metadata.terms.length
      ? metadata.terms
      : [
          {
            term: "",
            description: "",
          },
        ],
  );

  // Update local state when feed metadata changes
  useEffect(() => {
    setFeedName(metadata.name);
    setKeyTerms(
      metadata.terms.length ? metadata.terms : [{ term: "", description: "" }],
    );
  }, [metadata]);

  const changeKeyTerm = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    index: number,
  ) => {
    const newKeyTerms = keyTerms.map((term, i) =>
      i === index ? { ...term, [field]: e.target?.value } : term,
    );
    setKeyTerms(newKeyTerms);
  };

  const handleRemoveKeyTerm = (index: number) => {
    setKeyTerms((keyTerms) => keyTerms.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    if (!feedName) {
      toast.error("Please enter a feed name");
      return false;
    }
    if (!keyTerms || !keyTerms.length) {
      toast.error("Please add at least one Company/Brand key term");
      return false;
    }
    if (keyTerms.some((term) => term.term.trim() === "")) {
      toast.error("Please enter a value for each Company/Brand key term");
      return false;
    }
    if (keyTerms.some((term) => term.description.trim() === "")) {
      toast.error("Please ensure all terms have descriptions");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    const data = {
      name: feedName,
      terms: keyTerms.map((term) => ({
        term: term.term,
        description: term.description || "",
      })),
    };
    panelMode === "add" ? createFeed(data) : editFeed(data);
  };

  const addKeyTerm = () => {
    const newKeyTerm: FeedDataTermsType = { term: "", description: "" };
    setKeyTerms([...keyTerms, newKeyTerm]);
  };

  const buttonText = panelMode === "add" ? "Add" : "Save";
  const savingText = isLoading
    ? "ing..."
    : panelMode === "add"
      ? " Newsfeed"
      : " Changes";

  return (
    <SheetContent
      hasClose={false}
      className="pb-5 px-0 max-w-md pt-0 flex flex-col h-screen"
    >
      <SheetTitle className="sticky z-10 top-0 bg-white px-5">
        <div className="flex flex-row justify-between items-center py-4">
          <h3 data-testid="panel-title">
            {panelMode === "add" ? "Add" : "Edit"} Newsfeed
          </h3>
          <div className="flex gap-2">
            <Button
              variant="default"
              isDisabled={isLoading}
              onPress={handleSubmit}
              data-testid="submit-button"
            >
              {buttonText}
              {savingText}
              {isLoading && (
                <CircleNotch
                  size={20}
                  className="ml-2 animate-spin"
                  weight="bold"
                />
              )}
            </Button>
          </div>
        </div>
        <div className="h-px w-full bg-slate-100" />
      </SheetTitle>

      <div className="flex flex-col w-full gap-7 pt-5 flex-grow overflow-y-auto px-5">
        <div className="w-full">
          <Label htmlFor="newsfeed-name">Newsfeed Name</Label>
          <Input
            autoFocus
            className="w-full"
            id="newsfeed-name"
            value={feedName}
            onChange={(e) => setFeedName(e.target.value)}
            placeholder="Enter a name for your newsfeed"
            disabled={isLoading}
            data-testid="newsfeed-name-input"
          />
        </div>
        <div className="w-full">
          <Label>Company/Brand key terms</Label>
          <p className="text-sm text-slate-500 mb-2">
            Enter key terms for news related to businesses, companies, or brands
            you want to see.
          </p>
          {keyTerms.map((term, index) => (
            <KeyTerm
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              term={term}
              disabled={isLoading}
              onChange={(e, field) => changeKeyTerm(e, field, index)}
              onRemove={() => handleRemoveKeyTerm(index)}
              canRemove={!!index}
            />
          ))}

          <Button
            variant="ghost"
            onPress={addKeyTerm}
            className="text-violet-950"
            isDisabled={isLoading}
            aria-label="Add key term"
            data-testid="add-key-term-button"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add
          </Button>
        </div>
      </div>
    </SheetContent>
  );
};

export default NewsFeedSettingPanel;
