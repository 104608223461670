import useAuth, { type AuthState } from "@/store/auth.slice";
import useFeedStore from "@/store/useFeedStore";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import type React from "react";
import { useEffect } from "react";
import { useShallow } from "zustand/shallow";
import { gb } from ".";

export const FeatureManagementProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { user, loggedIn, isEmployee } = useAuth(
    useShallow((state: AuthState) => ({
      user: state.user,
      loggedIn: state.loggedIn,
      isEmployee: state.isEmployee,
    })),
  );
  const feedId = useFeedStore(useShallow((state) => state.feedId));

  useEffect(() => {
    const attributes = {
      id: user?.id,
      loggedIn,
      email: user?.email,
      company: user?.default_tenant?.slug,
      employee: isEmployee,
      deviceId: feedId,
    };

    gb.setAttributes(attributes);
  }, [
    user?.id,
    loggedIn,
    user?.email,
    user?.default_tenant?.slug,
    isEmployee,
    feedId,
  ]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};
