import { toast } from "@/components/ui/toast";
import { deleteFeedArticleMutation } from "@/data-access/news";
import useArticleDeletionStore from "@/store/news/articleDelete.slice";
import { removeArticles } from "@/store/news/articles.actions";
import { useParams } from "react-router-dom";
import { useMutation } from "urql";
import { useShallow } from "zustand/shallow";

export const useDeleteFeedArticle = () => {
  const [articleIdsToDelete, feedIdToDeleteFromNewsFeed] =
    useArticleDeletionStore(
      useShallow((state) => [
        state.articleIdsToDelete,
        state.feedIdToDeleteFromNewsFeed,
      ]),
    );
  const [, removeArticle] = useMutation(deleteFeedArticleMutation);
  const { feedId } = useParams();

  const handleDeleteArticle = async (reason: string) => {
    if (!feedId && !feedIdToDeleteFromNewsFeed) return;

    try {
      const { error } = await removeArticle({
        input: {
          feedId: feedId || `${feedIdToDeleteFromNewsFeed}`,
          feedArticleId: articleIdsToDelete.map((id) => id.toString()),
          reason: reason,
        },
      });

      if (!error) {
        removeArticles(articleIdsToDelete, reason);
        toast({
          type: "neutral",
          title: "Articles successfully deleted",
          description: `${articleIdsToDelete.length} article(s) have been removed from your newsfeed.`,
        });
      } else {
        toast({
          type: "error",
          title: "Unable to delete articles",
          description: "0 articles were deleted from your newsfeed.",
        });
      }
    } catch (error) {
      toast({
        type: "error",
        title: "Unable to delete articles",
        description: "0 articles were deleted from your newsfeed.",
      });
    }
  };

  return { handleDeleteArticle };
};
