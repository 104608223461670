import { isLocal } from "@/config";
import useAuth, { type AuthState } from "@/store/auth.slice";
import * as Sentry from "@sentry/react";
import type React from "react";
import { useEffect } from "react";
import { loadDatadog } from "./datadog";
import { initializeAnalytics } from "./initializeAnalytics";
import initializeLogging from "./initializeLogging";

export const AnalyticsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Initialize analytics services
  useEffect(() => {
    if (!isLocal) {
      initializeLogging();
      const unsubscribe = initializeAnalytics();
      return () => unsubscribe();
    }
  }, []);

  // Set up user tracking
  useEffect(() => {
    const unsubscribe = useAuth.subscribe((state: AuthState) => {
      const { loggedIn, user, isEmployee } = state;
      const userData = {
        id: user?.id,
        email: user?.email,
        company: user?.default_tenant?.slug || null,
        loggedIn,
        employee: isEmployee,
      };

      Sentry.setUser(userData);
      loadDatadog().then((datadogRum) => {
        datadogRum.setUser({
          ...userData,
          id: userData.id?.toString(),
        });
      });
    });

    return () => unsubscribe();
  }, []);

  return <>{children}</>;
};
