import { isDevelopment } from "@/config";
import type { EnhancerOptions } from "@redux-devtools/extension";
import { devtools, persist } from "zustand/middleware";
import { createWithEqualityFn as create } from "zustand/traditional";
import createUrlStorage from "./createUrlStorage";
import { type ArticlesSlice, createArticlesSlice } from "./news/articles.slice";
import { type FeedSlice, createFeedSlice } from "./news/feed.slice";
import { FilterCategory } from "./news/feed.types";
import createFeedParamsSlice, {
  type ParamsStorageSlice,
} from "./news/feedParams.slice.ts";
import {
  type FilterAndSearchSlice,
  createFilterSearchSlice,
} from "./news/filterSearch.slice";

type FeedStore = FeedSlice & ArticlesSlice & FilterAndSearchSlice;

const useFeedStore = create<FeedStore>()(
  devtools(
    (...a) => ({
      ...createFeedSlice(...a),
      ...createArticlesSlice(...a),
      ...createFilterSearchSlice(...a),
    }),
    {
      name: "feedStore",
      enabled: isDevelopment,
      store: "feed",
      serialize: true,
    } as EnhancerOptions,
  ),
);

const useFeedParamsStore = create<ParamsStorageSlice>()(
  persist(
    devtools(
      (...a) => ({
        ...createFeedParamsSlice(...a),
      }),
      {
        name: "feedParamsStore",
        enabled: isDevelopment,
        store: "feedParams",
      },
    ),
    {
      name: "fd",
      storage: createUrlStorage(),
      version: 1,
      migrate: (persistedState: any, version: number) => {
        const defaultState: ParamsStorageSlice = {
          selected: {
            [FilterCategory.CATEGORY]: ["News", "Press Release", "Owned Media"],
          },
          searchTerms: "",
          filterDays: 30,
          isCustomDaysRange: false,
          period: "day",
          sortOrder: "DATE_DESC",
        };

        // Handle version upgrades if needed in the future
        if (!persistedState || version === 0) return defaultState;

        return {
          ...defaultState,
          ...persistedState,
          // Ensure required fields have default values
          selected: {
            ...defaultState.selected,
            ...(persistedState.selected || {}),
          },
        };
      },
    },
  ),
);

export default useFeedStore;
export { useFeedParamsStore };
