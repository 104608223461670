import type { DerivedFilterItem } from "@/store/news/feed.slice";
import { memo } from "react";
import FilterContainer from "./components/FilterContainer";

interface FilterComponentProps {
  filterKey: string;
  title: string;
  filters: DerivedFilterItem[];
  filterByTags: (tags: string[]) => void;
  loading: boolean;
  sorted: boolean;
  handleEditClick?: (tier: string) => Promise<void>;
}

function FilterComponent(props: FilterComponentProps) {
  return <FilterContainer {...props} />;
}

export default memo(FilterComponent, (prevProps, nextProps) => {
  // Quick primitive comparisons first
  if (prevProps.filterKey !== nextProps.filterKey) return false;
  if (prevProps.loading !== nextProps.loading) return false;
  if (prevProps.sorted !== nextProps.sorted) return false;
  if (prevProps.title !== nextProps.title) return false;
  if (prevProps.filters.length !== nextProps.filters.length) return false;

  // Compare each filter's properties
  return prevProps.filters.every((prevFilter, index) => {
    const nextFilter = nextProps.filters[index];
    if (!nextFilter) return false;
    return (
      prevFilter.id === nextFilter.id &&
      prevFilter.value === nextFilter.value &&
      prevFilter.totalCount === nextFilter.totalCount &&
      prevFilter.count === nextFilter.count &&
      prevFilter.selected === nextFilter.selected
    );
  });
});
