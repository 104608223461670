import { Button } from "@/components/ui/button";
import { Input, TextArea } from "@/components/ui/textfield";
import { ArrowElbowDownRight, Trash } from "@phosphor-icons/react";

interface KeyTermProps {
  term: { term: string; description: string };
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onChange: (e: any, field: string) => void;
  onRemove: () => void;
  canRemove: boolean;
  disabled: boolean;
}

const KeyTerm = ({
  term,
  onChange,
  onRemove,
  canRemove,
  disabled,
}: KeyTermProps) => {
  return (
    <div className="flex gap-4 w-full items-center mb-2">
      <div className="flex-grow flex flex-col gap-2">
        <Input
          className="flex-grow"
          value={term.term}
          onChange={(e) => onChange(e, "term")}
          placeholder="Company (Example: Google)"
          autoFocus
          disabled={disabled}
          data-testid="key-term-input"
        />
        <div className="flex gap-2">
          <ArrowElbowDownRight size="16" />
          <TextArea
            value={term.description}
            className="h-[120px]"
            disabled={disabled}
            onChange={(e) => onChange(e, "description")}
            placeholder="Description (e.g., 'Global tech company known for search, ads, devices, and online services.') This helps us get you accurate data!"
            data-testid="key-term-description"
          />
        </div>
      </div>
      {canRemove && (
        <Button
          isDisabled={disabled}
          variant="secondary"
          size="icon"
          onPress={onRemove}
          aria-label="Remove key term"
          data-testid="remove-key-term-button"
        >
          <Trash className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default KeyTerm;
