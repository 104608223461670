import { graphql } from "../../graphql";
import {
  FeedConfigurationFragment,
  FeedFragment,
  FeedPageV2Fragment,
} from "../fragments/feed-fragments";

export const FeedOverviewQuery = graphql(
  `
    query FeedOverviewQuery {
      feeds {
        ...FeedFragment
        updatedAt
        jobLastRun
      }
    }
  `,
  [FeedFragment],
);

export const SideBarDataQuery = graphql(
  `
    query SideBarDataQuery {
      feeds {
        ...FeedFragment
        terms {
          term
          description
        }
        knownTags {
          tag
        }
      }
      campaigns {
        id
        name
      }
    }
  `,
  [FeedFragment],
);

export const SingleFeedDataV2Query = graphql(
  `
    query singleFeedDataV2($feedArticleId: ID!, $feedId: ID!) {
      singleFeedDataV2(feedArticleId: $feedArticleId, feedId: $feedId) {
        ...FeedPageV2Fragment
      }
      ...FeedConfiguration
    }
  `,
  [FeedPageV2Fragment, FeedConfigurationFragment],
);

export const FilterSidebarQuery = graphql(
  `
    query FeedFilterSidebar(
      $feedId: Int!
      $startDate: DateTime!
      $endDate: DateTime!
    ) {
      precomputeCounts(
        filters: { feedId: $feedId, startDate: $startDate, endDate: $endDate }
      ) {
        counts {
          category
          items {
            key
            label
            ids
          }
        }
        total
      }
    }
  `,
);

export const FeedArticlesQuery = graphql(
  `
    query FeedArticlesPaged(
      $endDate: Date!
      $startDate: Date!
      $after: String!
      $feedId: Int!
      $first: Int!
      $score: Ordering = null
      $datePublished: Ordering = DESC_NULLS_LAST
      $ids: [Int!]
    ) {
      feedArticles(
        first: $first
        filters: {
          dateRange: { startDate: $startDate, endDate: $endDate }
          feedId: $feedId
          ids: $ids
          excluded: false
        }
        order: { datePublished: $datePublished, customScore: $score }
        after: $after
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        totalCount
        edges {
          node {
            id
            prominence
            tier
            article {
              id
              url
              headline
              authors {
                id
                name
              }
              mainImage {
                id
                image {
                  fullSize
                }
              }
              category
              genericSummaries {
                text
              }
              datePublished
              createdAt
              publisher {
                id
                name
                domainAuthorityScore
                monthlyVisitors
                url
                logoUrl
              }
              social {
                totalSocial
              }
            }
            feedSummary {
              text
            }
            sentiment {
              polarity
              rationale
            }
            score {
              customScore
            }
            firstMention {
              term
              snippet
              location
            }
            overrides {
              headline
              prominence
              sentiment {
                polarity
                rationale
              }
              publisher {
                id
                logoUrl
                name
                url
              }
              summary
              datePublished
              category
              authors {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
);

export const ReadershipConfigurationQuery = graphql(
  `
    query ReadershipConfigurationQuery {
      ...FeedConfiguration
    }
  `,
  [FeedConfigurationFragment],
);

export type FeedConfigurationInputType = ReturnType<
  Exclude<typeof FeedConfigurationFragment.__apiType, undefined>
>;
