import type { StateCreator } from "zustand";

export interface FilterComponentState {
  [key: string]: boolean; // key is component id, value is open/closed state
}

export interface FilterComponentStateSlice {
  filterComponentStates: FilterComponentState;
  setFilterComponentState: (componentId: string, isOpen: boolean) => void;
  toggleFilterComponentState: (componentId: string) => void;
}

export const createFilterComponentStateSlice: StateCreator<
  FilterComponentStateSlice
> = (set) => ({
  filterComponentStates: {
    IMPACT_SCORE: true, // Impact Score filter open by default
  },
  setFilterComponentState: (componentId: string, isOpen: boolean) =>
    set((state) => ({
      filterComponentStates: {
        ...state.filterComponentStates,
        [componentId]: isOpen,
      },
    })),
  toggleFilterComponentState: (componentId: string) =>
    set((state) => ({
      filterComponentStates: {
        ...state.filterComponentStates,
        [componentId]: !state.filterComponentStates[componentId],
      },
    })),
});
