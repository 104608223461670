import Sidebar from "@/components/common/Sidebar";
import { MEDIUM_WINDOW_WIDTH } from "@/config";
import { useTokenManager } from "@/hooks/useTokenManager";
import { useAppDispatch, useAppSelector } from "@/lib/hooks/storeHooks";
import { cn } from "@/lib/utils";
import { setLeftSidebarVisible } from "@/store/actions/builder";
import { useWindowSize } from "@uidotdev/usehooks";
import { type ReactNode, useLayoutEffect, useState } from "react";

interface MainLayoutProps {
  children: ReactNode | ReactNode[];
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  useTokenManager();
  const { width } = useWindowSize();
  const [isSmallWindow, setSmallWindow] = useState(false);

  const dispatch = useAppDispatch();
  const { isSidebarVisible } = useAppSelector((state) => state.builder);

  useLayoutEffect(() => {
    if (!width) return;
    const isSmall = width <= MEDIUM_WINDOW_WIDTH;
    setSmallWindow(isSmall);
    if (isSmall) {
      dispatch(setLeftSidebarVisible(false));
    }
  }, [width]);

  const shouldHoverExpanded = isSmallWindow && isSidebarVisible;

  return (
    <div
      className={cn(
        "h-screen overflow-hidden relative",
        !shouldHoverExpanded && "grid grid-cols-[min-content_1fr]",
      )}
    >
      <Sidebar
        collapsed={!isSidebarVisible}
        className={cn(shouldHoverExpanded && "absolute z-40")}
      />
      <div className={cn(shouldHoverExpanded && "pl-[55px]")}>{children}</div>
    </div>
  );
};

export default MainLayout;
