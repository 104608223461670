import type { FeedWithAugTypes } from "@/data-access/news";
import type { StateCreator } from "zustand";

export type ArticlesSlice = {
  feedItems: Partial<FeedWithAugTypes>[];
  allFeedIds: Set<number>;
  filteredFeedIds: Set<number>;
  cursor: string | null;
  hasNextPage: boolean;
  fetchingFeed: boolean;
  continueFetchingFeed: boolean;
};

export const createArticlesSlice: StateCreator<
  ArticlesSlice,
  [["zustand/devtools", never]],
  []
> = () => ({
  feedItems: [],
  cursor: null,
  allFeedIds: new Set<number>(),
  filteredFeedIds: new Set<number>(),
  hasNextPage: false,
  fetchingFeed: true,
  continueFetchingFeed: false,
});
