import type { FeedDataTermsType, FeedWithAugTypes } from "@/data-access/news";
import { derive } from "@/lib/derive-zustand";
import { isSameDay, isWithinInterval, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useStore } from "zustand";
import useFeedStore, { useFeedParamsStore } from "../useFeedStore";
import useArticleDeletionStore from "./articleDelete.slice";
import type { DerivedFilterItem } from "./feed.slice";
import { FilterCategory } from "./feed.types";

interface FeedMetadata {
  name: string;
  terms: FeedDataTermsType[];
  tags: string[];
}

const deriveFeedMetadata = derive<FeedMetadata>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const feed = feedId
    ? (feedSidebarData.find((f) => +f?.id === feedId) ?? null)
    : null;
  // Explicitly track all feed properties we depend on
  const name = feed?.name ?? "";
  const terms = feed?.terms ?? [];
  const tags = feed?.knownTags?.map((t) => t.tag) ?? [];
  return { name, terms, tags };
});

const deriveArticleIds = derive<number[]>((get) => {
  const { feedItems } = get(useFeedStore);
  return feedItems
    .map((item) => item.id)
    .filter((id): id is number => id !== undefined);
});

// Date range check
const isWithinRange = (
  itemDate: string | undefined | null,
  startOfPeriod: string | null,
  endOfPeriod: string | null,
): boolean => {
  if (!itemDate || !startOfPeriod) return false;

  const parsedItemDate = toZonedTime(parseISO(itemDate), "UTC");
  const parsedStartDate = toZonedTime(parseISO(startOfPeriod), "UTC");

  return !endOfPeriod
    ? isSameDay(parsedItemDate, parsedStartDate)
    : isWithinInterval(parsedItemDate, {
        start: parsedStartDate,
        end: toZonedTime(parseISO(endOfPeriod), "UTC"),
      });
};

// Filter and search selectors
const deriveFilterOrSearch = derive<boolean>((get) => {
  const { searchTerms, selected } = get(useFeedParamsStore);
  const { categoryAppliedFilters } = get(useFeedStore);
  return (
    searchTerms?.length > 0 ||
    Object.values(categoryAppliedFilters).some(
      (filter) => (selected[filter?.name ?? ""]?.length ?? 0) > 0,
    )
  );
});

const deriveFilteredFeedItems = derive<Partial<FeedWithAugTypes>[]>((get) => {
  const { feedItems, filteredFeedIds, filterFeedIdSearch, selectedStackIds } =
    get(useFeedStore);
  const { searchTerms } = get(useFeedParamsStore);

  // Filter items based on filtered IDs
  let filteredItems = feedItems.filter((item) =>
    filteredFeedIds.has(+(item.id ?? -1)),
  );

  if (selectedStackIds?.length > 0) {
    filteredItems = filteredItems.filter((item) =>
      selectedStackIds.includes(+(item.id ?? -1)),
    );
  }

  // If search is active but returned no matches, return empty array to show "no results" UI
  if (searchTerms && filterFeedIdSearch.length === 0) {
    return [];
  }

  // If search is active, only show items that matched the search
  if (searchTerms) {
    const searchIds = new Set(filterFeedIdSearch.map(([id]) => id));
    filteredItems = filteredItems.filter((item) =>
      searchIds.has(+(item.id ?? -1)),
    );
  }

  // Apply search hits to items
  return filteredItems.map((item) => {
    const searchHit = filterFeedIdSearch.find(([id]) => item.id === id)?.[1];
    return {
      ...item,
      searchHit: searchHit ?? undefined,
    } as Partial<FeedWithAugTypes>;
  });
});

interface FilterCounts {
  selection: number;
  displayed: number;
  total: number;
}

// Filter counts and totals
const deriveFilterCounts = derive<FilterCounts>((get) => {
  const {
    filteredFeedIds,
    filterFeedIdSearch,
    selectedStackIds,
    feedVolume,
    totalCount,
  } = get(useFeedStore);
  const { searchTerms } = get(useFeedParamsStore);
  const deleteIds = new Set(get(useArticleDeletionStore).deletedArticleIds);

  const getActiveCount = (ids: Set<number> | number[]) =>
    new Set(Array.isArray(ids) ? ids : Array.from(ids)).difference(deleteIds)
      .size;

  const displayedTotal =
    selectedStackIds.length > 0
      ? getActiveCount(filteredFeedIds.intersection(new Set(selectedStackIds)))
      : feedVolume !== null
        ? searchTerms.length > 0
          ? getActiveCount(filterFeedIdSearch.map(([id]) => id))
          : feedVolume
        : getActiveCount(
            searchTerms.length > 0
              ? filterFeedIdSearch.map(([id]) => id)
              : filteredFeedIds,
          );

  return {
    selection: getActiveCount(filteredFeedIds),
    displayed: displayedTotal,
    total: totalCount ?? 0,
  };
});

const deriveFilterGroups = derive<
  {
    key: string;
    title: string;
    filters: DerivedFilterItem[];
    sorted: boolean;
  }[]
>((get) => {
  const { categoryAppliedFilters, fetchingFilters, filteredFeedIds } =
    get(useFeedStore);
  const { selected } = get(useFeedParamsStore);
  const deleteIds = new Set(get(useArticleDeletionStore).deletedArticleIds);

  return Object.entries(categoryAppliedFilters)
    .filter(([_, v]) => v)
    .map(([key, value]) => ({
      key,
      title: value?.name ?? key,
      sorted: value?.sorted ?? true,
      filters: !value?.itemCounts
        ? []
        : Object.entries(value.itemCounts)
            .sort(([a], [b]) =>
              key === FilterCategory.SOCIAL || key === FilterCategory.READERSHIP
                ? a === "0"
                  ? 1
                  : b === "0"
                    ? -1
                    : 0
                : 0,
            )
            .map(([filter, ids]) => ({
              id: key + filter,
              value: filter,
              totalCount: fetchingFilters
                ? 0
                : new Set([...ids].filter((id) => !deleteIds.has(id))).size,
              count: fetchingFilters
                ? 0
                : filteredFeedIds.intersection(new Set(ids)).size,
              selected: new Set(selected[key] || []).has(filter),
            })),
    }));
});

interface Percentages {
  selection: number | null;
  displayed: number | null;
}

// Analytics selectors
const derivePercentages = derive<Percentages>((get) => {
  const { selection, displayed, total } = get(deriveFilterCounts);
  const calcPercent = (value: number) =>
    total ? Math.round((value / total) * 100) : null;

  return {
    selection: calcPercent(selection),
    displayed: calcPercent(displayed),
  };
});

// Hooks
const useArticleIds = () => useStore(deriveArticleIds);
const useFeedMetadata = () => useStore(deriveFeedMetadata);
const useIsFilterOrSearch = () => useStore(deriveFilterOrSearch);
const useFilteredFeedItems = () => useStore(deriveFilteredFeedItems);
const useFilterGroups = () => useStore(deriveFilterGroups);
const useFilterCounts = () => useStore(deriveFilterCounts);
const usePercentages = () => useStore(derivePercentages);

// Exports
export {
  useArticleIds,
  useFeedMetadata,
  useIsFilterOrSearch,
  useFilteredFeedItems,
  useFilterGroups,
  useFilterCounts,
  usePercentages,
  // For testing
  deriveFilterGroups,
  isWithinRange,
};
