import FeedArticleRemove from "@/components/news/article/FeedArticleRemove";
import FeedEmptyState from "@/components/news/feed/FeedEmptyState";
import FeedItem from "@/components/news/feed/FeedItem";
import { Button } from "@/components/ui/button";
import ActionBar from "@/components/ui/molecules/ActionBar";
import type { FeedWithAugTypes } from "@/data-access/news";
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName";
import useArticleDeletionStore from "@/store/news/articleDelete.slice";
import { fetchNextPage } from "@/store/news/feed.actions";
import type { FilterData } from "@/store/news/feed.slice";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { Trash } from "@phosphor-icons/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useShallow } from "zustand/shallow";
import FeedFilterHeader from "./FeedFilterHeader";
import FeedLoadingIndicator from "./FeedLoadingIndicator";
import { FeedSearch } from "./FeedSearch";
import FeedVolumeGraph from "./FeedVolumeGraph/FeedVolumeGraph";

interface FeedContentProps {
  feedId: string;
  searchTerms: string;
  feedItems: Partial<FeedWithAugTypes>[];
  className?: string;
}

const FeedContent: React.FC<FeedContentProps> = ({
  feedId,
  searchTerms,
  feedItems,
  className,
}) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<HTMLDivElement>(null);
  const [feedSearchTop, setFeedSearchTop] = useState(0);

  const fetchingFeed = useFeedStore((state) => state.fetchingFeed);
  const fetchingSearch = useFeedStore((state) => state.fetchingSearch);
  const fetchingFilters = useFeedStore((state) => state.fetchingFilters);
  const isEmptyFeed = useFeedStore((state) => state.feedItems.length === 0);
  const categoryAppliedFilters = useFeedStore(
    (state) => state.categoryAppliedFilters,
  );
  const hasNextPage = useFeedStore((state) => state.hasNextPage);
  const continueFetchingFeed = useFeedStore(
    (state) => state.continueFetchingFeed,
  );

  const fetching = fetchingFeed || fetchingSearch || fetchingFilters;

  const selected = useFeedParamsStore((state) => state.selected);

  const [
    articleIdsToDelete,
    closeActionBar,
    isDeleteModalOpen,
    setDeleteModalOpen,
  ] = useArticleDeletionStore(
    useShallow((state) => [
      state.articleIdsToDelete,
      state.closeActionBar,
      state.isDeleteModalOpen,
      state.setDeleteModalOpen,
    ]),
  );

  const prevSearchTermsRef = useRef(searchTerms);
  const prevFeedIdRef = useRef(feedId);

  useEffect(() => {
    prevSearchTermsRef.current = searchTerms;
    prevFeedIdRef.current = feedId;
  });

  useEffect(() => {
    if (mainRef.current) {
      const shouldAdjustScroll =
        searchTerms !== prevSearchTermsRef.current ||
        feedId !== prevFeedIdRef.current;

      if (shouldAdjustScroll) {
        const scrollToPosition = () => {
          const graphHeight =
            graphRef.current?.getBoundingClientRect().height || 0;
          const currentScrollTop = mainRef.current?.scrollTop || 0;
          const targetScrollTop = Math.min(currentScrollTop, graphHeight);
          mainRef.current?.scrollTo(0, targetScrollTop);
        };
        scrollToPosition();
      }
    }
  }, [feedId, searchTerms, feedItems]);

  // Observer for triggering data fetching well in advance for paged feeds
  const handlePreloadIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (!target) return;

      if (
        target.isIntersecting &&
        !fetching &&
        !fetchingSearch &&
        !continueFetchingFeed &&
        hasNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetching, fetchingSearch, continueFetchingFeed, hasNextPage],
  );

  useEffect(() => {
    const ITEM_HEIGHT = 500;
    const ITEMS_BEFORE_LOAD = 3.5;
    const marginBottom = Math.round(ITEM_HEIGHT * ITEMS_BEFORE_LOAD);

    const preloadObserver = new IntersectionObserver(
      handlePreloadIntersection,
      {
        root: mainRef.current,
        rootMargin: `0px 0px ${marginBottom}px 0px`,
        threshold: 0,
      },
    );

    if (loaderRef.current) {
      preloadObserver.observe(loaderRef.current);
    }

    return () => {
      preloadObserver.disconnect();
    };
  }, [handlePreloadIntersection]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === headerRef.current) {
          const newHeight = entry.contentRect.height;
          setFeedSearchTop(newHeight);
        }
      }
    });
    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current);
      }
    };
  }, []);

  const handleCloseFeedArticleRemoveModal = () => {
    setDeleteModalOpen(false);
    closeActionBar();
  };

  const filterData: FilterData[] = Object.entries(categoryAppliedFilters).map(
    ([key, value]) => {
      const options = Object.entries(value.itemCounts ?? {});
      return {
        options,
        selectedOptions: selected[key] ?? [],
        title: value.name ?? key,
        categoryKey: key,
      };
    },
  );

  const numberOfArticles = articleIdsToDelete.length;
  const showLoader = hasNextPage || continueFetchingFeed;

  return (
    <div className={className}>
      {numberOfArticles ? (
        <ActionBar onClose={closeActionBar}>
          <div className="flex justify-between items-center w-full">
            <div className="text-sm ml-6">
              <span className="font-semibold pr-1">
                {addCommasToNumbers(numberOfArticles)}
              </span>
              <span>{pluralize(numberOfArticles, "article")} selected</span>
            </div>
            <Button
              variant="outline"
              onPressChange={() => setDeleteModalOpen(true)}
            >
              <Trash size={16} className="text-red-750" />
              <span className="ml-2">Delete</span>
            </Button>
          </div>
        </ActionBar>
      ) : null}

      <FeedArticleRemove
        isOpen={isDeleteModalOpen}
        onClose={handleCloseFeedArticleRemoveModal}
        numberOfItemsToDelete={numberOfArticles}
      />

      <div
        ref={mainRef}
        className="bg-white overflow-y-auto overflow-x-hidden h-[calc(100vh-80px)] min-w-[932px] margin-auto flex-grow"
      >
        <FeedFilterHeader filterData={filterData} ref={headerRef} />
        <div ref={graphRef}>
          <FeedVolumeGraph />
        </div>
        <FeedSearch style={{ top: `${feedSearchTop}px` }} />

        <div className="lg:max-w-[932px] lg:mx-auto flex flex-col pb-7">
          {fetching && !continueFetchingFeed ? (
            Array.from({ length: 4 }, (_, index) => (
              <FeedItem
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={index}
                isLoading={true}
                id={index}
                setFeedArticleRemoveModalOpen={() => {}}
              />
            ))
          ) : feedItems?.length === 0 ? (
            <FeedEmptyState
              title={
                isEmptyFeed
                  ? "We're gathering your news"
                  : "No items match your filters"
              }
              body={
                isEmptyFeed
                  ? "We're searching billions of sources. Please check back soon."
                  : "Try adjusting your filters to find the news you're looking for."
              }
            />
          ) : (
            <>
              {feedItems.map((item) => (
                <FeedItem
                  key={item.id}
                  setFeedArticleRemoveModalOpen={setDeleteModalOpen}
                  {...item}
                />
              ))}
              <div ref={loaderRef}>
                {showLoader && <FeedLoadingIndicator />}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedContent;
