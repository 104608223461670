import NewsFeedPublisherPanel from "@/components/news/feed/panels/NewsFeedPublisherPanel";
import { Sheet } from "@/components/ui/controls/sheet";
import { useAppNavigation } from "@/lib/navigation";
import useFeedPanelStore from "@/store/news/feedPanel.slice";
import useFeedStore from "@/store/useFeedStore";
import usePublishersStore from "@/store/usePublishersStore";
import { useEffect, useRef } from "react";
import { useShallow } from "zustand/shallow";
import NewsFeedSettingPanel from "../NewsFeedSettingPanel";

const FeedSheets: React.FC = () => {
  const { feedId, creatingOrEditingFeed, createOrEditFeedState } = useFeedStore(
    useShallow((state) => ({
      feedId: state.feedId,
      creatingOrEditingFeed: state.creatingOrEditingFeed,
      createOrEditFeedState: state.createOrEditFeedState,
    })),
  );
  const { isOpen, closePanel } = useFeedPanelStore(
    useShallow((state) => ({
      isOpen: state.isOpen,
      mode: state.mode,
      closePanel: state.closePanel,
    })),
  );
  const displayPanel = usePublishersStore(
    useShallow((state) => state.displayPanel),
  );

  const feedIdRef = useRef(feedId);
  const navigateToFeed = useAppNavigation().navigateToFeed;

  useEffect(() => {
    if (!creatingOrEditingFeed && createOrEditFeedState === "complete") {
      closePanel();
      if (feedId !== feedIdRef.current && feedId) {
        navigateToFeed(feedId);
      }
    }
  }, [creatingOrEditingFeed, createOrEditFeedState, feedId]);

  return (
    <>
      <Sheet
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) closePanel();
        }}
      >
        <NewsFeedSettingPanel />
      </Sheet>
      <Sheet open={displayPanel}>
        {displayPanel && <NewsFeedPublisherPanel />}
      </Sheet>
    </>
  );
};

export default FeedSheets;
