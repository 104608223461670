import { graphql } from "@/data-access/graphql";
import type { ChartColorInput } from "@/lib/utils/chartUtils";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { useShallow } from "zustand/shallow";
import { colorKeysByStackedBy } from "./volumeHelpers";

export const GetFeedVolumeQueryAndStackByLabels = graphql(`
  query GetFeedVolume(
    $input: VolumeDataInput!
    $filters: FeedArticleFilter!
    $enum: String!
  ) {
    feedVolumeData(input: $input, filters: $filters) {
      startOfPeriod
      endOfPeriod
      volume
      stacked {
        ids
        label
        value
      }
    }
    enumLabels(enum: $enum)
  }
`);

type GetFeedVolumeVariablesType = Parameters<
  Exclude<typeof GetFeedVolumeQueryAndStackByLabels.__apiType, undefined>
>[0];

export type FeedVolumeData = NonNullable<
  Awaited<
    ReturnType<
      Exclude<
        (typeof GetFeedVolumeQueryAndStackByLabels)["__apiType"],
        undefined
      >
    >
  >["feedVolumeData"]
>[number];

export const useGetFeedVolume = () => {
  // url params
  const { feedId } = useParams();

  const [stackFeedVolumeBy, filteredIds] = useFeedStore(
    useShallow((state) => [state.stackFeedVolumeBy, state.filteredFeedIds]),
  );

  const [endDate, filterDays, startDate, isCustomDaysRange, period] =
    useFeedParamsStore(
      useShallow((state) => [
        state.endDate,
        state.filterDays,
        state.startDate,
        state.isCustomDaysRange,
        state.period,
      ]),
    );

  const startDateFormatted = startDate ? format(startDate, "yyyy-MM-dd") : null;
  const endDateFormatted = endDate ? format(endDate, "yyyy-MM-dd") : null;

  const input: GetFeedVolumeVariablesType["input"] = {
    period,
    daysSince: isCustomDaysRange ? null : filterDays,
    stackBy: stackFeedVolumeBy,
  };

  const filters: GetFeedVolumeVariablesType["filters"] = {
    // biome-ignore lint/style/noNonNullAssertion: <feedId won't be null>
    feedId: Number.parseInt(feedId!),
    dateRange: {
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    },
    ids: Array.from(filteredIds),
    excluded: false,
  };

  const fetchingFilters = useFeedStore((state) => state.fetchingFilters);

  const [result] = useQuery({
    query: GetFeedVolumeQueryAndStackByLabels,
    variables: {
      input,
      filters,
      enum: stackFeedVolumeBy ? stackFeedVolumeBy : "",
    },
    pause: fetchingFilters,
  });

  const { data, fetching } = result;

  return {
    feedVolume: transformFeedVolumeDataWithIds(data?.feedVolumeData ?? []),
    fetching,
    stackByLabels: (data?.enumLabels as string[]) ?? [],
  };
};

export type Period = "day" | "week" | "month" | null;

type FormatterOptions = {
  startDate: string | undefined | number;
  endDate: string | undefined | number;
};

export const getFormatterForPeriod = (
  period: Period,
  { startDate, endDate }: FormatterOptions,
): string => {
  const normalizeDate = (
    date: string | number | undefined,
  ): string | undefined => {
    if (typeof date === "number") {
      return new Date(date).toISOString();
    }
    return date;
  };

  // Normalize startDate, endDate
  const normalizedStartDate = normalizeDate(startDate);
  const normalizedEndDate = normalizeDate(endDate);

  if (period && normalizedStartDate && normalizedEndDate) {
    const parseDate = (date: string) => toZonedTime(parseISO(date), "UTC");
    if (period === "day") {
      return format(parseDate(normalizedStartDate), "MMM dd, yyyy");
    }

    if (period === "week") {
      const start = format(parseDate(normalizedStartDate), "MMM d");
      const end = format(parseDate(normalizedEndDate), "d, yyyy");
      return `${start}-${end}`;
    }

    if (period === "month") {
      return format(parseDate(normalizedStartDate), "MMM yyyy");
    }
  }

  return "";
};

export type StackByKey = keyof typeof colorKeysByStackedBy | null;

export function getColorsForStackBy(
  stackBy: StackByKey,
): ChartColorInput[] | undefined {
  if (!stackBy) return ["violet"] as ChartColorInput[];

  if (
    ["readership", "domain_authority", "social", "content_category"].includes(
      stackBy,
    )
  ) {
    return colorKeysByStackedBy.social as ChartColorInput[];
  }

  if (Object.prototype.hasOwnProperty.call(colorKeysByStackedBy, stackBy)) {
    return colorKeysByStackedBy[stackBy] as ChartColorInput[];
  }

  return ["violet"] as ChartColorInput[];
}

export const transformFeedVolumeDataWithIds = (
  data: FeedVolumeData[],
): Array<Record<string, string | number | number[]>> => {
  return data.map((item) => {
    const { startOfPeriod, endOfPeriod, stacked, volume } = item;

    const transformedItem: Record<string, string | number | number[]> = {
      startOfPeriod,
      endOfPeriod,
      volume,
    };

    if (stacked) {
      for (const stack of stacked) {
        if (stack.label && stack.value !== null) {
          transformedItem[stack.label] = stack.value;
          transformedItem[`${stack.label}-Ids`] = stack.ids;
        }
      }
    }

    return transformedItem;
  });
};
