import type { StateCreator } from "zustand";
import { FilterDisplayNames } from "./constants";
import { FilterCategory } from "./feed.types";

const TIER_1 = "Tier 1";
const TIER_2 = "Tier 2";
const TIER_3 = "Tier 3";

export type FilterValues = {
  name?: string;
  itemCounts: Record<string, Set<number>>;
  sorted?: boolean;
};
export type CategoryFilter = Partial<FilterValues>;
export type CategoryAppliedFilter = Record<string, CategoryFilter>;

export type FilterAndSearchSlice = {
  fetchingFilters: boolean;
  fetchingSearch: boolean;
  categoryAppliedFilters: CategoryAppliedFilter;
  unsubscribeFetchFilters?: () => void;
  unsubscribeFetchDynamicFilters?: () => void;
  filterFeedIdSearch: [
    number,
    searchHit?: {
      mention: string;
      term: string;
    },
  ][];
};

export const createFilterSearchSlice: StateCreator<
  FilterAndSearchSlice,
  [["zustand/devtools", never]],
  []
> = () => ({
  fetchingSearch: false,
  fetchingFilters: false,
  categoryAppliedFilters: {
    [FilterCategory.IMPACT_SCORE]: {
      name: FilterDisplayNames[FilterCategory.IMPACT_SCORE],
      itemCounts: {
        "80 to 100": new Set<number>(),
        "60 to 79": new Set<number>(),
        "40 to 59": new Set<number>(),
        "20 to 39": new Set<number>(),
        "0 to 19": new Set<number>(),
        "-1 to -19": new Set<number>(),
        "-20 to -39": new Set<number>(),
        "-40 to -59": new Set<number>(),
        "-60 to -79": new Set<number>(),
        "-80 to -100": new Set<number>(),
      },
      sorted: false,
    },
    [FilterCategory.PROMINENCE]: {
      name: FilterDisplayNames[FilterCategory.PROMINENCE],
      itemCounts: {
        Headline: new Set<number>(),
        Feature: new Set<number>(),
        Lede: new Set<number>(),
        "Passing Mention": new Set<number>(),
      },
      sorted: false,
    },
    [FilterCategory.PUBLICATION_TIER]: {
      name: FilterDisplayNames[FilterCategory.PUBLICATION_TIER],
      itemCounts: {
        [TIER_1]: new Set<number>(),
        [TIER_2]: new Set<number>(),
        [TIER_3]: new Set<number>(),
      },
      sorted: false,
    },
    [FilterCategory.SENTIMENT]: {
      name: FilterDisplayNames[FilterCategory.SENTIMENT],
      itemCounts: {
        Positive: new Set<number>(),
        Neutral: new Set<number>(),
        Negative: new Set<number>(),
      },
      sorted: false,
    },
    [FilterCategory.SOCIAL]: {
      name: FilterDisplayNames[FilterCategory.SOCIAL],
      sorted: false,
    },
    [FilterCategory.READERSHIP]: {
      name: FilterDisplayNames[FilterCategory.READERSHIP],
      sorted: false,
    },
    [FilterCategory.DOMAIN_AUTHORITY]: {
      name: FilterDisplayNames[FilterCategory.DOMAIN_AUTHORITY],
      itemCounts: Array.from({ length: 10 }, (_, i): [string, Set<number>] => [
        `${90 - i * 10}-${100 - (i === 0 ? 0 : i * 10 + 1)}`,
        new Set<number>(),
      ]).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, Set<number>>,
      ),
      sorted: false,
    },
    [FilterCategory.CATEGORY]: {
      name: FilterDisplayNames[FilterCategory.CATEGORY],
      itemCounts: {},
    },
    [FilterCategory.PUBLISHER]: {
      name: FilterDisplayNames[FilterCategory.PUBLISHER],
      itemCounts: {},
    },
    [FilterCategory.AUTHOR]: {
      name: FilterDisplayNames[FilterCategory.AUTHOR],
      itemCounts: {},
    },
  },
  filterFeedIdSearch: [],
});
