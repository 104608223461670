import { graphql } from "@/data-access/graphql";

export const CreateKeyMessageGroup = graphql(`
  mutation createKeyMessageGroup($input: CreateKeyMessageGroupInput!) {
    createKeyMessageGroup(input: $input) {
      ... on KeyMessageGroupType {
        id
        name
      }
    }
  }
`);

export const CreateKeyMessage = graphql(`
  mutation createKeyMessage($input: CreateKeyMessageItemInput!) {
    createKeyMessageItem(input: $input) {
      ... on KeyMessageItemType {
        id
        name
        group {
          id
          name
        }
      }
    }
  }
`);

export const GetKeyMessageGroups = graphql(`
  query getKeyMessageGroups($filters: KeyMessageGroupFilter) {
    keyMessageGroups(filters: $filters) {
      id
      name
    }
    keyMessageItems {
      id
      name
    }
  }
`);

export const GetKeyMessages = graphql(`
  query getKeyMessages($filters: KeyMessageItemFilter) {
    keyMessageItems(filters: $filters) {
      id
      name
      group {
        id
        name
      }
    }
  }
`);

export type CreateKeyMessageGroupVariablesType = Parameters<
  Exclude<typeof CreateKeyMessageGroup.__apiType, undefined>
>[0];

export type CreateKeyMessageVariablesType = Parameters<
  Exclude<typeof CreateKeyMessage.__apiType, undefined>
>[0];

export type GetKeyMessageGroupsVariablesType = Parameters<
  Exclude<typeof GetKeyMessageGroups.__apiType, undefined>
>[0];

export type GetKeyMessageGroupsResponseType = ReturnType<
  Exclude<typeof GetKeyMessageGroups.__apiType, undefined>
>;

export type GetKeyMessagesVariablesType = Parameters<
  Exclude<typeof GetKeyMessages.__apiType, undefined>
>[0];

export type GetKeyMessagesResponseType = ReturnType<
  Exclude<typeof GetKeyMessages.__apiType, undefined>
>;
