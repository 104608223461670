import type { StoreApi } from "zustand/vanilla";

/** Our version of StoreApi without the deprecated destroy method */
type DeriveStoreApi<T> = Omit<StoreApi<T>, "destroy">;

/** The getter function type available inside deriveFn. */
type Getter<TState> = {
  (): TState | undefined;
  <T>(store: DeriveStoreApi<T>): T;
};

/** The signature of the derive function: how to derive TState from base stores. */
type DeriveFn<TState> = (get: Getter<TState>) => TState;

/** A property path, e.g. ['nested', 0, 'value'] or ['Symbol(special)']. */
type PropertyPath = ReadonlyArray<string | number | symbol>;

/** String representation of a property path. */
type PathString = string;

/** Tracks which store has which property paths accessed. */
type AccessedProperties = Map<StoreApi<unknown>, Set<PathString>>;

/** Convert a property path array to a string like "nested/0/value". */
function pathToString(path: PropertyPath): PathString {
  return path
    .map((segment) =>
      typeof segment === "symbol" ? segment.toString() : String(segment),
    )
    .join("/");
}

/** Convert a path string "nested/0/value" back to a property path array. */
function stringToPath(pathString: PathString): PropertyPath {
  return pathString.split("/").map((segment) => {
    if (segment.startsWith("Symbol(")) {
      const symbolName = segment.slice(7, -1);
      return Symbol.for(symbolName);
    }
    return segment;
  });
}

/** Utility to check if a value is a non-null object. */
function isObject(value: unknown): value is Record<PropertyKey, unknown> {
  return typeof value === "object" && value !== null;
}

/** Deeply compare two unknown values for equality (primitives, arrays, objects, maps, sets). */
function deepCompare(a: unknown, b: unknown): boolean {
  if (Object.is(a, b)) return true;

  // If either is null or not an object -> false
  if (!isObject(a) || !isObject(b)) {
    return false;
  }

  // Handle Map
  if (a instanceof Map && b instanceof Map) {
    if (a.size !== b.size) return false;
    for (const [key, val] of a) {
      if (!b.has(key) || !deepCompare(val, b.get(key))) return false;
    }
    return true;
  }

  // Handle Set
  if (a instanceof Set && b instanceof Set) {
    if (a.size !== b.size) return false;
    for (const val of a) {
      if (!b.has(val)) return false;
    }
    return true;
  }

  // Handle Array
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!deepCompare(a[i], b[i])) return false;
    }
    return true;
  }

  // Plain object compare
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  if (keysA.length !== keysB.length) return false;
  for (const key of keysA) {
    if (!(key in b) || !deepCompare(a[key], b[key])) {
      return false;
    }
  }
  return true;
}

/**
 * Safely walk an unknown object by path. If at any point we can't index further,
 * returns `undefined`.
 */
function getPropertyValue(obj: unknown, path: PropertyPath): unknown {
  let current: unknown = obj;
  for (const key of path) {
    if (!isObject(current)) {
      return undefined;
    }
    current = current[key];
  }
  return current;
}

type AnyStore = StoreApi<unknown>;

/** Helper function to track property access */
function trackPropertyAccess(
  store: AnyStore,
  path: PropertyPath,
  prop: string | symbol,
  isDeriving: boolean,
  newAccessedProps: Map<AnyStore, Set<PathString>>,
) {
  const newPath = [...path, prop];
  const pathString = pathToString(newPath);

  if (isDeriving) {
    const accessed = newAccessedProps.get(store) || new Set<PathString>();
    accessed.add(pathString);
    newAccessedProps.set(store, accessed);
  }
}

/** Synchronous derive store that notifies listeners immediately. */
export function derive<TState>(
  deriveFn: DeriveFn<TState>,
): DeriveStoreApi<TState> {
  type Listener = (newState: TState, oldState: TState) => void;

  // All the usual stuff
  const listeners = new Set<Listener>();
  const subscriptions = new Map<AnyStore, () => void>();
  let state: TState | undefined;
  let dependencies: Map<AnyStore, unknown> | undefined;
  let accessedProps: AccessedProperties | undefined;
  let invalidated = true;
  let isNotifying = false; // Prevent reentrant notifications
  let isDeriving = false; // True while deriveFn runs

  /** Synchronously re-derive, compare states, and call listeners if changed. */
  function notifyListeners() {
    if (!invalidated || isNotifying) return;
    isNotifying = true;

    const prevState = state as TState;
    const newStateDerived = getState(); // re-derive if needed

    const hasDerivedStateChanged = !deepCompare(prevState, newStateDerived);
    if (hasDerivedStateChanged) {
      for (const listener of listeners) {
        listener(newStateDerived, prevState);
      }
    }

    isNotifying = false;
  }

  /** Check if relevant property paths changed -> set invalidated and notify. */
  function invalidate(store: AnyStore): void {
    if (invalidated || !dependencies || !accessedProps || isNotifying) {
      return;
    }

    const oldState = dependencies.get(store);
    const newState = store.getState();
    const accessed = accessedProps.get(store);
    if (!accessed) return;

    let hasChanges = false;
    for (const pathString of accessed) {
      const path = stringToPath(pathString);
      const oldValue = getPropertyValue(oldState, path);
      const newValue = getPropertyValue(newState, path);

      if (
        (oldValue instanceof Map && newValue instanceof Map) ||
        (oldValue instanceof Set && newValue instanceof Set)
      ) {
        if (!deepCompare(oldValue, newValue)) {
          hasChanges = true;
          break;
        }
      } else {
        if (!Object.is(oldValue, newValue)) {
          hasChanges = true;
          break;
        }
      }
    }

    if (hasChanges) {
      invalidated = true;
      notifyListeners();
    }
  }

  /** Recompute derived state if invalidated; track property accesses. */
  function getState(): TState {
    if (!invalidated && state !== undefined) {
      return state;
    }

    const newDependencies = new Map<AnyStore, unknown>();
    const newAccessedProps = new Map<AnyStore, Set<PathString>>();

    /** Create a proxy for Map with proper typing */
    function createMapProxy<K, V>(
      map: Map<K, V>,
      store: AnyStore,
      path: PropertyPath = [],
    ): Map<K, V> {
      return new Proxy(map, {
        get(target, prop, receiver) {
          // Handle 'size' property
          if (prop === "size") {
            trackPropertyAccess(
              store,
              path,
              prop,
              isDeriving,
              newAccessedProps,
            );
            return target.size;
          }

          // Handle Symbol.iterator
          if (prop === Symbol.iterator) {
            trackPropertyAccess(
              store,
              path,
              prop,
              isDeriving,
              newAccessedProps,
            );
            return target[Symbol.iterator].bind(target);
          }

          const value = Reflect.get(target, prop, receiver);
          const newPath = [...path, prop];

          // Track property accesses only if inside deriveFn
          if (isDeriving) {
            trackPropertyAccess(
              store,
              path,
              prop,
              isDeriving,
              newAccessedProps,
            );
          }

          // Bind methods correctly
          if (typeof value === "function") {
            return value.bind(target);
          }

          // For other properties, return them directly or wrap if they are objects
          return isObject(value) ? createProxy(value, store, newPath) : value;
        },
        ownKeys: (target) => Reflect.ownKeys(target),
        getOwnPropertyDescriptor: (target, prop) =>
          Reflect.getOwnPropertyDescriptor(target, prop),
      }) as Map<K, V>;
    }

    /** Create a proxy for Set with proper typing */
    function createSetProxy<T>(
      set: Set<T>,
      store: AnyStore,
      path: PropertyPath = [],
    ): Set<T> {
      return new Proxy(set, {
        get(target, prop, receiver) {
          // Handle 'size' property
          if (prop === "size") {
            trackPropertyAccess(
              store,
              path,
              prop,
              isDeriving,
              newAccessedProps,
            );
            return target.size;
          }

          // Handle Symbol.iterator
          if (prop === Symbol.iterator) {
            trackPropertyAccess(
              store,
              path,
              prop,
              isDeriving,
              newAccessedProps,
            );
            return target[Symbol.iterator].bind(target);
          }

          const value = Reflect.get(target, prop, receiver);
          const newPath = [...path, prop];

          // Track property accesses only if inside deriveFn
          if (isDeriving) {
            trackPropertyAccess(
              store,
              path,
              prop,
              isDeriving,
              newAccessedProps,
            );
          }

          // Bind methods correctly
          if (typeof value === "function") {
            return value.bind(target);
          }

          // For other properties, return them directly or wrap if they are objects
          return isObject(value) ? createProxy(value, store, newPath) : value;
        },
        ownKeys: (target) => Reflect.ownKeys(target),
        getOwnPropertyDescriptor: (target, prop) =>
          Reflect.getOwnPropertyDescriptor(target, prop),
      }) as Set<T>;
    }

    /** General proxy creation with type assertions */
    function createProxy(
      obj: unknown,
      store: AnyStore,
      path: PropertyPath = [],
    ): unknown {
      if (!isObject(obj)) {
        return obj; // Return primitives directly
      }

      if (obj instanceof Map) {
        // Infer generic types if possible
        return createMapProxy(obj, store, path);
      }

      if (obj instanceof Set) {
        // Infer generic types if possible
        return createSetProxy(obj, store, path);
      }

      // General object proxy handling
      return new Proxy(obj, {
        get(target, prop, receiver) {
          const value = Reflect.get(target, prop, receiver);
          const newPath = [...path, prop];

          // Track property accesses only if inside deriveFn
          if (isDeriving) {
            trackPropertyAccess(
              store,
              path,
              prop,
              isDeriving,
              newAccessedProps,
            );
          }

          // Bind functions to the target
          if (typeof value === "function") {
            return value.bind(target);
          }

          // Wrap objects recursively
          return isObject(value) ? createProxy(value, store, newPath) : value;
        },
        ownKeys: (target) => Reflect.ownKeys(target),
        getOwnPropertyDescriptor: (target, prop) =>
          Reflect.getOwnPropertyDescriptor(target, prop),
      });
    }

    const get: Getter<TState> = <T>(
      store?: StoreApi<T>,
    ): T | TState | undefined => {
      if (!store) {
        return state;
      }
      const s = store.getState();
      newDependencies.set(store, s);
      return createProxy(s, store) as T;
    };

    // Recompute by calling deriveFn
    isDeriving = true;
    const newState = deriveFn(get);
    isDeriving = false;

    state = newState;
    dependencies = newDependencies;
    accessedProps = newAccessedProps;
    invalidated = false;

    // manage subscriptions
    const currentStores = new Set<AnyStore>(dependencies.keys());
    for (const [store, unsub] of subscriptions) {
      if (!currentStores.has(store)) {
        unsub();
        subscriptions.delete(store);
      }
    }
    for (const store of currentStores) {
      if (!subscriptions.has(store)) {
        const unsub = store.subscribe(() => invalidate(store));
        subscriptions.set(store, unsub);
      }
    }

    return state as TState;
  }

  /** Subscribe a listener to the derived store. */
  function subscribe(listener: Listener): () => void {
    // Force an initial computation to set up dependencies if needed
    if (dependencies === undefined) {
      getState();
    }

    listeners.add(listener);

    return () => {
      listeners.delete(listener);
      if (!listeners.size) {
        // Clean up all subscriptions when last listener is removed
        for (const unsub of subscriptions.values()) {
          unsub();
        }
        subscriptions.clear();
        invalidated = true;
      }
    };
  }

  // The derived store API
  const store: DeriveStoreApi<TState> = {
    getState,
    subscribe,
    getInitialState: () => {
      throw new Error("getInitialState is not available in a derived store");
    },
    setState: () => {
      throw new Error("setState is not available in a derived store");
    },
  };

  return store;
}
