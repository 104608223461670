import FilterPill from "@/components/ui/data-display/filterPill";
import DateRangePicker, {
  type onApplyFn,
} from "@/components/ui/date-range-picker";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Skeleton } from "@/components/ui/feedback/skeleton.tsx";
import { useDownloadFile } from "@/data-access/core/useDownloadFile";
import { cn } from "@/lib/utils";
import { addCommasToNumbers } from "@/lib/utils/prettyName";
import useAuth from "@/store/auth.slice";
import { setPeriod, updateDateRange } from "@/store/news/feed.actions";
import type { FilterData } from "@/store/news/feed.slice";
import { getDateRangeType } from "@/store/news/feed.slice";
import useFeedPanelStore from "@/store/news/feedPanel.slice";
import { updateSelectedFilters } from "@/store/news/filters.actions";
import {
  useFeedMetadata,
  useFilterCounts,
  usePercentages,
} from "@/store/news/news.selectors";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import {
  DotsThree,
  Export,
  FloppyDisk,
  Funnel,
  Gear,
} from "@phosphor-icons/react";
import { format, toZonedTime } from "date-fns-tz";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useShallow } from "zustand/shallow";

type FeedFilterHeaderProps = {
  filterData: FilterData[];
};

const FeedFilterHeader = forwardRef<HTMLDivElement, FeedFilterHeaderProps>(
  ({ filterData }, forwardedRef) => {
    const openEditPanel = useFeedPanelStore((state) => state.openEditPanel);

    const [feedId, isFetchingFilters] = useFeedStore(
      useShallow((state) => [state.feedId, state.fetchingFilters]),
    );

    const filteredFeedItemsCount = useFilterCounts();

    const [endDate, filterDays, isCustomDaysRange, startDate] =
      useFeedParamsStore(
        useShallow((state) => [
          state.endDate,
          state.filterDays,
          state.isCustomDaysRange,
          state.startDate,
        ]),
      );

    // feed details
    const { name: feedName } = useFeedMetadata();
    const { selection: filterPercentage } = usePercentages();
    const containerRef = useRef<HTMLDivElement>(null);

    const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [visibleFilters, setVisibleFilters] = useState<FilterData[]>([]);
    const [initiallyHiddenFilters, setInitiallyHiddenFilters] = useState<
      FilterData[]
    >([]);

    // user
    const user = useAuth((state) => state.user);
    const tenant = user?.tenants?.[0]?.slug;

    const {
      ref: downloadRef,
      fileUrl,
      download,
      fileName,
    } = useDownloadFile({
      onError: (e) => console.error(e),
      getFileName: () => `${feedName}-${new Date().toISOString()}-export.csv`,
    });

    const downloadFile = () => {
      let endpoint = `/articles/feed/${feedId}/export/?daysFilter=${filterDays}`;
      if (startDate && endDate) {
        const formattedStartDate = format(
          toZonedTime(startDate, "UTC"),
          "yyyy-MM-dd",
        );
        const formattedEndDate = format(
          toZonedTime(endDate, "UTC"),
          "yyyy-MM-dd",
        );
        endpoint = `/articles/feed/${feedId}/export/?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      download(endpoint, tenant);
    };

    const onApply: onApplyFn = (range) => {
      setPeriod(getDateRangeType(range.from, range.to, range.days));
      updateDateRange(range.days, range.from, range.to, range.isCustom);
    };

    // render filter pills
    const generateFilterPill = ({
      selectedOptions,
      categoryKey,
      title,
    }: FilterData) => (
      <FilterPill
        key={categoryKey}
        title={title}
        selectedOptions={selectedOptions}
        setSelectedOptions={(options) =>
          updateSelectedFilters(categoryKey, options ?? [])
        }
      />
    );

    // render feed percentage
    const renderFeedPercentage = () => {
      return (
        <span>
          {isFetchingFilters ? (
            <Skeleton className="w-32 h-4 bg-slate-300/50" />
          ) : (
            `${addCommasToNumbers(
              filteredFeedItemsCount.selection,
            )} of ${addCommasToNumbers(filteredFeedItemsCount.total)} ${
              filterPercentage &&
              filterPercentage !== 100 &&
              !Number.isNaN(filterPercentage)
                ? `(${filterPercentage}%) `
                : ""
            }`
          )}
        </span>
      );
    };

    useEffect(() => {
      const validFilters = filterData.filter(
        ({ selectedOptions }) => selectedOptions && selectedOptions.length > 0,
      );
      const validFilterCount = validFilters.length;
      setIsFiltersApplied(validFilterCount > 0);
      const calculateLines = () => {
        const container = containerRef.current;
        if (container) {
          const moreButtonWidth = 45;
          const xGap = 10;
          const containerWidth = container.offsetWidth;
          let rowWidth = 0;
          let visibleCount = 0;
          let lineCount = 0;
          const visible: FilterData[] = [];
          const remaining: FilterData[] = [];

          const pillElements = container.querySelectorAll("[data-filter-pill]");
          for (const [index, option] of validFilters.entries()) {
            const child = pillElements[index] as HTMLElement;
            const childWidth = child?.offsetWidth ? child.offsetWidth : 0;
            const moreButtonWidthToAdd =
              index !== validFilterCount - 1 ? moreButtonWidth : 0;

            if (
              rowWidth + childWidth + moreButtonWidthToAdd + xGap >
              containerWidth
            ) {
              rowWidth = childWidth;
              lineCount++;
            } else {
              rowWidth += childWidth + xGap;
            }

            if (lineCount >= 2) {
              remaining.push(option);
            } else {
              visible.push(option);
              visibleCount++;
            }
          }
          setVisibleFilters(visible);
          setInitiallyHiddenFilters(remaining);
        }
      };
      setVisibleFilters(validFilters);
      setInitiallyHiddenFilters([]);
      const timeout = setTimeout(() => {
        calculateLines();
      }, 0);
      window.addEventListener("resize", calculateLines);
      return () => {
        window.removeEventListener("resize", calculateLines);
        clearTimeout(timeout);
      };
    }, [filterData]);

    return (
      <div
        className="max-w-[956px] lg:mx-auto top-0 z-30 sticky px-3 bg-white"
        ref={forwardedRef}
      >
        <div className={"flex items-end justify-between pb-4"}>
          <div className="flex flex-row pt-4 mr-2">
            <div className="flex flex-row gap-3">
              <Funnel
                className={cn(
                  "h-4 w-4 mt-[7px] flex-shrink-0",
                  isFiltersApplied ? "text-gray-800" : "text-gray-400",
                )}
              />
              <div
                className={"text-xs text-gray-600 min-w-[85px] leading-[30px]"}
              >
                {isFiltersApplied ? "Filters Applied: " : "No filters selected"}
              </div>
              <div
                ref={containerRef}
                className={cn(
                  "flex flex-row flex-wrap gap-2.5 relative items-center",
                  isExpanded ? "max-h-fit" : "max-h-[70px] overflow-hidden",
                )}
              >
                {visibleFilters.map((data) => generateFilterPill(data))}
                {initiallyHiddenFilters.length > 0 && !isExpanded && (
                  <div
                    className="cursor-pointer text-xs text-gray-900 leading-4"
                    onClick={() => setIsExpanded(true)}
                  >
                    <b>+{initiallyHiddenFilters.length}</b> more
                  </div>
                )}
                {initiallyHiddenFilters.map((data) => generateFilterPill(data))}
                {initiallyHiddenFilters.length > 0 && isExpanded && (
                  <div
                    className="cursor-pointer text-xs text-gray-900 leading-4"
                    onClick={() => setIsExpanded(false)}
                  >
                    Show less
                  </div>
                )}
              </div>
            </div>
          </div>
          <a
            href={fileUrl}
            download={fileName}
            className="hidden"
            ref={downloadRef}
          >
            download
          </a>
          <DropdownMenu>
            <DropdownMenuTrigger className={"mr-3"}>
              <DotsThree size={24} />
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuContent className={"min-w-56"} sideOffset={5}>
                <DropdownMenuItem
                  className={"text-xs text-gray-600 pl-4"}
                  disabled
                >
                  More Actions
                </DropdownMenuItem>
                <DropdownMenuItem className={"cursor-pointer pl-4"}>
                  <FloppyDisk size={16} className={"text-gray-600"} />
                  Save as subfeed
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={"cursor-pointer pl-4"}
                  onClick={downloadFile}
                >
                  <Export size={16} className={"text-gray-600"} />
                  Export
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={"cursor-pointer pl-4"}
                  onClick={openEditPanel}
                >
                  <Gear size={16} className={"text-gray-600"} />
                  Settings
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenu>
        </div>
        <div className={"flex justify-between ml-1"}>
          <div className={"flex flex-col text-xs"}>
            <span className={"font-semibold pb-2 tracking-wide"}>
              Total Mentions Over Time
            </span>
            {renderFeedPercentage()}
          </div>
          <div className={"flex flex-col"}>
            <DateRangePicker
              filterDays={filterDays}
              isCustomDaysRange={isCustomDaysRange}
              onApply={onApply}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default FeedFilterHeader;
