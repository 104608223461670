import { toast } from "@/components/ui/toast";
import { isDevelopment } from "@/config";
import { apiEndpointBaseUrl } from "@/data-access/core/constants";
import { type UserInfo, defaultUserInfo } from "@/types/user/userInfo";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type AuthStatus = "idle" | "loading" | "succeeded" | "failed";

type LoginPayload = {
  email: string;
  password: string;
};

type GoogleLoginPayload = {
  client_id: string;
  access_token: string;
};

type RegisterPayload = {
  email: string;
  password1: string;
  password2: string;
};

type ResetPasswordConfirmPayload = {
  uid: string | null;
  token: string | null;
  new_password1: string;
  new_password2: string;
};

type AuthResponse = {
  user?: UserInfo;
  access?: string;
  refresh?: string;
  detail?: string;
  email?: string[];
  non_field_errors?: string[];
};

export interface AuthSlice {
  user: UserInfo;
  status: AuthStatus;
  loggedIn: boolean;
  error: string | null;
  isEmployee: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  startLogin: (payload: LoginPayload) => Promise<void>;
  logoutUser: () => Promise<void>;
  setUser: (user: UserInfo) => void;
  startGoogleLogin: (payload: GoogleLoginPayload) => Promise<void>;
  startResetPassword: (email: string) => Promise<void>;
  startRegister: (payload: RegisterPayload) => Promise<void>;
  resetPasswordConfirm: (payload: ResetPasswordConfirmPayload) => Promise<void>;
  changePassword: (
    new_password1: string,
    new_password2: string,
  ) => Promise<void>;
}

const errorMessages = {
  invalidCredentials:
    "The username or password you entered is incorrect. Please try again.",
  failedRegistration:
    "We encountered an issue while creating your account. Please try again later.",
  googleLoginFailed:
    "There was an issue logging in with Google. Please retry or use a different login method.",
  resetPasswordFailed: "An error occurred. Please try again later.",
  resetPassConfirmFailed: "An error occurred. Please try again later.",
  changePasswordFailed: "Failed to change password. Please try again.",
};

const logAndUpdateStatus = (error: unknown) => {
  const message = error instanceof Error ? error.message : String(error);
  console.error(error);
  toast.error(message);
  return { status: "failed" as const, error: message };
};

export type AuthState = AuthSlice & { resetState: () => void };

type SetState = {
  (partial: Partial<AuthState>, replace?: false): void;
  (partial: (state: AuthState) => Partial<AuthState>, replace?: false): void;
};

const setAuthState = (set: SetState, body: AuthResponse) => {
  if (!body) {
    console.error("Missing body in setAuthState:", body);
    return;
  }

  set({
    user: body.user || defaultUserInfo,
    accessToken: body.access || null,
    refreshToken: body.refresh || null,
    loggedIn: Boolean(body.access),
    isEmployee: body.user?.email?.endsWith("@handraise.com") ?? false,
    status: "succeeded",
    error: null,
  });
};

// Keys that should be cleared on logout
const SESSION_STORAGE_KEYS = [
  "persist:root",
  "session-storage",
  "auth-storage",
];

const clearStorage = () => {
  // Clear storage items
  for (const key of SESSION_STORAGE_KEYS) {
    window.localStorage.removeItem(key);
  }
};

const useAuth = create<AuthState>()(
  devtools(
    persist(
      (set, get) => ({
        user: defaultUserInfo,
        status: "idle",
        loggedIn: false,
        error: null,
        isEmployee: false,
        accessToken: null,
        refreshToken: null,
        resetState: () => {
          set({
            user: defaultUserInfo,
            status: "idle",
            loggedIn: false,
            error: null,
            isEmployee: false,
            accessToken: null,
            refreshToken: null,
          });
          clearStorage();
        },
        setUser: (user: UserInfo) => set({ user }),
        changePassword: async (
          new_password1: string,
          new_password2: string,
        ) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(
              `${apiEndpointBaseUrl}/auth/password/change/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${get().accessToken}`,
                },
                body: JSON.stringify({ new_password1, new_password2 }),
              },
            );
            if (resp.ok) {
              toast.success("Password changed successfully");
              set({ status: "succeeded", error: null });
            } else {
              throw new Error(errorMessages.changePasswordFailed);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },
        logoutUser: async () => {
          const token = get().accessToken;

          try {
            if (token) {
              await fetch(`${apiEndpointBaseUrl}/auth/logout/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
            }
          } catch (error) {
            console.error("Error when logging out:", error);
          } finally {
            set({
              user: defaultUserInfo,
              status: "idle",
              loggedIn: false,
              error: null,
              isEmployee: false,
              accessToken: null,
              refreshToken: null,
            });
            clearStorage();
          }
        },
        startLogin: async ({ email, password }: LoginPayload) => {
          set({ status: "loading", error: null });
          try {
            const resp = await fetch(`${apiEndpointBaseUrl}/auth/login/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email, password }),
            });
            const body = await resp.json();

            if (resp.ok) {
              if (body.access) {
                setAuthState(set, body);
              } else {
                throw new Error(errorMessages.invalidCredentials);
              }
            } else {
              throw new Error(errorMessages.invalidCredentials);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },
        startRegister: async ({
          email,
          password1,
          password2,
        }: RegisterPayload) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(
              `${apiEndpointBaseUrl}/auth/registration/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password1, password2 }),
              },
            );
            const body = await resp.json();
            if (resp.ok) {
              setAuthState(set, body);
            } else {
              console.error(
                "Error Registering",
                body.email,
                body.non_field_errors,
              );
              throw new Error(errorMessages.failedRegistration);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },
        startGoogleLogin: async ({
          client_id,
          access_token,
        }: GoogleLoginPayload) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(`${apiEndpointBaseUrl}/auth/gauth/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ client_id, access_token }),
            });
            const body = await resp.json();
            if (resp.ok) {
              setAuthState(set, body);
            } else {
              console.error(body.non_field_errors);
              throw new Error(errorMessages.googleLoginFailed);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },
        startResetPassword: async (email: string) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(
              `${apiEndpointBaseUrl}/auth/password/reset/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
              },
            );
            const body = await resp.json();
            if (resp.ok) {
              toast.success(body.detail);
              set({ status: "succeeded", error: null });
            } else {
              throw new Error(errorMessages.resetPasswordFailed);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },
        resetPasswordConfirm: async (payload: ResetPasswordConfirmPayload) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(
              `${apiEndpointBaseUrl}/auth/password/reset/confirm/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              },
            );
            const body = await resp.json();
            if (resp.ok) {
              toast.success(body.detail);
              set({ status: "succeeded", error: null });
            } else {
              throw new Error(errorMessages.resetPassConfirmFailed);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },
      }),
      {
        name: "auth-storage",
        storage: createJSONStorage(() => localStorage),
      },
    ),
    {
      name: "authStore",
      enabled: isDevelopment,
      store: "auth",
    },
  ),
);

export default useAuth;
