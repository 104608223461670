import ArrowIcon from "@/assets/svg/arrow-up-right.svg?react";
import { apiEndpointBaseUrl } from "@/data-access/core/constants";
import { useCampaignsTrackCreateMutation } from "@/store/generated-apis/campaignApi";
import type { IArticleImage } from "@/types/article";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";

interface IArticlePreview {
  headline?: string;
  image: IArticleImage;
  logo?: string;
  short_url: string;
  source_url: string;
  summary: string;
  publication?: string;
  utm_url?: string;
  tenant?: string;
  tenant_logo?: string;
  authors?: string[];
  date_published?: string;
  button_color?: string;
  button_text?: string;
  font_color?: string;
}

const ArticlePreview = () => {
  const { id } = useParams();

  const [article, setArticle] = React.useState<IArticlePreview>();

  const [createCampaignTrack] = useCampaignsTrackCreateMutation();

  React.useEffect(() => {
    // if there is no ID return to home page
    if (!id) {
      window.location.href = "/";
      return;
    }

    const getArticle = async () => {
      const res = await fetch(
        `${apiEndpointBaseUrl}/campaigns/ad-redirect/${id}/`,
      );
      const data = await res.json();
      setArticle(data);
    };

    getArticle();
  }, [id]);

  useEffect(() => {
    if (article?.short_url)
      createCampaignTrack({
        eventTracker: {
          event_type: "PAGE_VIEW",
          ad_short_url: article?.short_url,
          extra_data: {
            REFERRER_URL: document.referrer,
          },
        },
      });
  }, [article?.short_url]);

  const trackArticleLinkClick = () => {
    if (!article?.short_url) return;
    createCampaignTrack({
      eventTracker: {
        event_type: "ARTICLE_LINK_CLICK",
        ad_short_url: article?.short_url,
      },
    });
  };

  const trackCTAClick = () => {
    if (!article?.short_url) return;
    createCampaignTrack({
      eventTracker: {
        event_type: "CALL_TO_ACTION_CLICK",
        ad_short_url: article?.short_url,
      },
    });
  };

  return (
    <div className="bg-slate-50 h-full min-h-screen">
      <div className="bg-slate-100 text-slate-600 text-sm text-center p-2">
        Powered by{" "}
        <a
          href="https://www.handraise.com/"
          target="_blank"
          className="text-violet-800"
          rel="noreferrer"
        >
          Handraise
        </a>
      </div>
      {article && (
        <div className="iframe-container flex rounded px-4 bg-slate-50 h-full">
          <div className="article-preview  h-fit max-w-[560px] mx-auto my-10 overflow-y-auto pb-10">
            {/* Article Title */}
            <div className="flex flex-col justify-between">
              <div className="flex flex-col">
                <h2
                  className="text-4xl  text-slate-800 mb-2 text-center article-title"
                  style={{
                    color: article?.font_color || "#190041",
                  }}
                >
                  {article?.headline || "No Headline found"}
                </h2>
              </div>
            </div>
            <div className="flex justify-center items-center py-2">
              <div className="flex items-center">
                {article.logo && (
                  <img
                    src={article?.logo}
                    className="max-h-[20px]"
                    alt={article?.publication}
                  />
                )}
              </div>
              <div className="flex items-center">
                <span className="mx-5 text-slate-500">•</span>
                <a
                  href={article?.source_url}
                  onClick={trackArticleLinkClick}
                  target="_blank"
                  className="flex items-center text-sm text-slate-800 underline"
                  rel="noreferrer"
                >
                  Visit article
                  <ArrowIcon />
                </a>
              </div>
            </div>

            {/* Main IMage */}
            {article?.image && (
              <div
                className="bg-article-preview bg-cover bg-center h-36 w-full relative my-4 min-[992px]:h-72 "
                style={{
                  backgroundImage: `url(${article?.image?.image?.large_portrait_crop})`,
                }}
              />
            )}

            {/* Article Description */}
            <div className="flex flex-col justify-between">
              <div className="flex flex-col">
                <ReactMarkdown
                  components={{
                    ul: ({ node, ...props }) => (
                      <ul className="list-disc ps-6 pt-2" {...props} />
                    ),
                    li: ({ node, ...props }) => (
                      <li
                        className="text-sm mb-2 text-slate-900"
                        style={{
                          color: article?.font_color || "#190041",
                        }}
                        {...props}
                      />
                    ),
                  }}
                >
                  {article?.summary ?? ""}
                </ReactMarkdown>
              </div>
            </div>
            <div className="bg-white p-8 text-center flex items-center flex-wrap justify-center mx-auto relative">
              {article?.tenant_logo && (
                <div
                  className="w-[100px] h-[100px] rounded-full overflow-hidden"
                  style={{
                    backgroundImage: `url(${article?.tenant_logo})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              )}
              <a
                href={article?.utm_url || article?.source_url}
                onClick={trackCTAClick}
                data-dd-action-name="CTA"
                // TODO: Fix this
                // biome-ignore lint/a11y/useSemanticElements: <old tracking>
                role="button"
                target="_blank"
                className="w-full bg-violet-900 rounded-lg text-white h-[48px] leading-[48px] mt-5"
                style={{
                  backgroundColor: article?.button_color || "#2B0E81",
                }}
                rel="noreferrer"
              >
                {article?.button_text || "Learn more"}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticlePreview;
