import { CaretLeft } from "@phosphor-icons/react";
import { type CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { HandraiseLogoMark } from "@/assets/handraise";
import { AuthForm } from "@/components/auth/AuthForm";
import BackgroundGradientLayout from "@/components/common/Gradient";
import { Button } from "@/components/ui/button";
import { type LoginData, loginSchema } from "@/lib/auth/authValidation";
import { cn } from "@/lib/utils";
import useAuth from "@/store/auth.slice";
import { useShallow } from "zustand/shallow";

const LoginScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [startLogin, startGoogleLogin, authStatus, loggedIn] = useAuth(
    useShallow((state) => [
      state.startLogin,
      state.startGoogleLogin,
      state.status,
      state.loggedIn,
    ]),
  );

  const redirectPath = location.state?.from?.pathname || "/newsfeeds";

  const handleGoogleLogin = (response: CredentialResponse) => {
    if (response.credential) {
      startGoogleLogin({
        client_id: response.clientId || "",
        access_token: response.credential,
      });
    }
  };

  const handleLogin = async (data: LoginData) => {
    await startLogin({
      email: data.email,
      password: data.password,
    });
  };

  useEffect(() => {
    if (loggedIn && authStatus === "succeeded") {
      navigate(redirectPath, { replace: true });
    }
  }, [loggedIn, authStatus, navigate, redirectPath]);

  return (
    <BackgroundGradientLayout>
      <div className="flex justify-center items-center h-screen">
        {redirectPath !== "/newsfeeds" && (
          <div className="absolute top-5 left-5">
            <Button
              variant={"ghost"}
              onPress={() => navigate("/newsfeeds")}
              className="text-gray-600 flex items-center"
            >
              <CaretLeft className="mr-2" />
              Cancel
            </Button>
          </div>
        )}

        <div
          className={cn(
            "w-full max-w-md px-8 pt-6 pb-5 mb-28",
            "bg-white/75 backdrop-blur-sm shadow-xl rounded-lg",
            "border border-gray-100/30",
          )}
        >
          <div className="w-full flex items-center justify-center mt-3 mb-6">
            <HandraiseLogoMark className="w-48" />
          </div>

          <h1 className="text-center text-lg font-serif text-gray-700">
            {redirectPath !== "/newsfeeds"
              ? `Login to access ${redirectPath}`
              : "Welcome back"}
          </h1>
          <p className="text-center text-gray-500 text-sm mb-6">
            Supercharge your news with AI
          </p>

          <div className="mb-6 w-full flex items-center justify-center">
            <GoogleLogin
              onSuccess={handleGoogleLogin}
              theme="outline"
              text="signup_with"
            />
          </div>

          <div className="relative mb-6 flex items-center justify-center">
            <div className="flex-grow border-t border-gray-300" />
            <span className="px-4 text-gray-500">or</span>
            <div className="flex-grow border-t border-gray-300" />
          </div>

          <AuthForm
            schema={loginSchema}
            onSubmit={handleLogin}
            fields={[
              {
                name: "email",
                label: "Email",
                type: "email",
              },
              {
                name: "password",
                label: "Password",
                type: "password",
              },
            ]}
            submitLabel="Sign In"
          />

          <div className="mt-4 text-center">
            <Link
              to="/auth/forgot-password"
              className="text-gray-700 text-sm hover:underline"
            >
              Forgot password?
            </Link>
          </div>

          <div className="mt-10 text-center text-xs text-gray-500">
            By signing in, you agree to our{" "}
            {/* <a href="#" className="text-violet-700"> */}
            privacy policy
            {/* </a> */}
          </div>
        </div>
      </div>
    </BackgroundGradientLayout>
  );
};

export default LoginScreen;
