import type {
  PayloadItem,
  TooltipProps,
} from "@/components/ui/bar-chart/BarChart.types";
import { cn } from "@/lib/utils";
import {
  type AvailableChartColorsKeys,
  getColorClassName,
} from "@/lib/utils/chartUtils";
import { addCommasToNumbers } from "@/lib/utils/prettyName";
import useArticleDeletionStore from "@/store/news/articleDelete.slice";
import useFeedStore from "@/store/useFeedStore";
import { memo, useMemo } from "react";
import { type Period, getFormatterForPeriod } from "./useFeedVolumeGraphData";
import { chartStackedByOptions } from "./volumeHelpers";

type FeedVolumeGraphTooltipProps = TooltipProps<PayloadItem> & {
  stackedBy: string | null;
  period: Period;
};

const FeedVolumeGraphTooltip = ({
  active,
  payload,
  stackedBy,
  period,
}: FeedVolumeGraphTooltipProps) => {
  const filteredCount = useFeedStore((state) => {
    const { filteredFeedIds } = state;
    const { deletedArticleIds } = useArticleDeletionStore.getState();
    const deleteIdsSet = new Set(deletedArticleIds);
    return Array.from(filteredFeedIds).filter((id) => !deleteIdsSet.has(id))
      .length;
  });

  const selectedStackByOption = useMemo(
    () => chartStackedByOptions.find((option) => option.value === stackedBy),
    [stackedBy],
  );

  const calculateCoveragePercentage = useMemo(
    () => (value: number, volume: number) => {
      if (stackedBy) {
        return Math.round((value / volume) * 100);
      }
      return Math.round((volume / filteredCount) * 100);
    },
    [stackedBy, filteredCount],
  );

  if (active && payload && payload.length) {
    return (
      <div
        className={cn(
          // base
          "rounded-md border text-[10px] shadow-md",
          // border color
          "border-gray-200 dark:border-gray-800",
          // background color
          "bg-white dark:bg-gray-950 w-auto",
          // text
          "min-w-48",
        )}
      >
        <div className={"px-4 pt-2 flex items-center"}>
          <p
            className={
              "text-gray-600 dark:text-gray-50 text-[8px] uppercase font-medium tracking-wide mr-auto"
            }
          >
            {selectedStackByOption?.value
              ? selectedStackByOption.label
              : "Volume (All)"}
          </p>
          {selectedStackByOption?.value !== "sentiment" &&
            selectedStackByOption?.value !== "impact_score" &&
            selectedStackByOption?.icon}
        </div>
        <div className={"space-y-1 px-4 pt-1 pb-2"}>
          {payload.map(
            ({ value, category, color, payload: payloadItem }, index) => {
              return (
                <div key={`id-${index}-${value}-${category}`}>
                  <div className="flex items-center space-x-2">
                    <span
                      aria-hidden="true"
                      className={cn(
                        "size-2 shrink-0 rounded",
                        getColorClassName(
                          color as AvailableChartColorsKeys,
                          "bg",
                          "solid",
                        ),
                      )}
                    />
                    {stackedBy ? (
                      <p
                        className={cn(
                          // base
                          "whitespace-nowrap text-right",
                          // text color
                          "text-gray-900 dark:text-gray-300 capitalize  text-xs",
                        )}
                      >
                        {category}
                      </p>
                    ) : (
                      <p
                        className={"text-gray-700 py-0.5"}
                      >{`${addCommasToNumbers(value)} articles`}</p>
                    )}
                  </div>
                  {stackedBy && (
                    <p
                      className={"text-gray-700 py-0.5"}
                    >{`${addCommasToNumbers(value)} articles`}</p>
                  )}
                  <p
                    className={"text-gray-700"}
                  >{`${calculateCoveragePercentage(value, payloadItem.volume)}% of ${getFormatterForPeriod(
                    period,
                    {
                      startDate: payloadItem.startOfPeriod,
                      endDate: payloadItem.endOfPeriod,
                    },
                  )} coverage`}</p>
                </div>
              );
            },
          )}
        </div>
      </div>
    );
  }
  return null;
};

FeedVolumeGraphTooltip.displayName = "FeedVolumeGraphTooltip";
export default memo(FeedVolumeGraphTooltip, (prevProps, nextProps) => {
  return (
    prevProps.active === nextProps.active &&
    prevProps.payload === nextProps.payload &&
    prevProps.label === nextProps.label
  );
});
